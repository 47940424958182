import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";
import { SearchInput } from "components";
import { useDispatch, useSelector } from "react-redux";
import { zoneActions } from "store/zone/slice";
import { AppState } from "store";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const ZoneListToolbar: React.FC<any> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const searchText = useSelector((state: AppState) => state.zone.searchText);

  const handleChange = useCallback(
    (event) => {
      dispatch(zoneActions.setSearchText(event.target.value));
    },
    [dispatch]
  );

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to={`/zones/add`}
        >
          Add zone
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          defaultValue={searchText}
          className={classes.searchInput}
          placeholder="Search zone"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default ZoneListToolbar;

import React, { useCallback, useState } from "react";
import { useThunkDispatch } from "store";
import { useHistory, useParams, Redirect } from "react-router-dom";
import ItemForm from "../ItemForm/ItemForm";
import { updateItem } from "store/item/actions";
import { useItem } from "store/item/selectors";
import { uploadFile, deleteFile } from "store/actions";

const ItemEdit: React.FC = () => {
  const thunkDispatch = useThunkDispatch();

  const history = useHistory();
  const { storeId, itemId } = useParams<{ storeId: string; itemId: string }>();
  const [image, setImage] = useState<Blob | null>(null);
  const item = useItem(storeId, itemId);

  const handleFinish = useCallback(
    async (values) => {
      try {
        let imageUri = null;
        if (image) {
          const imageUpload = await thunkDispatch(
            uploadFile({ path: `images/${storeId}/items`, file: image })
          );
          setImage(null);
          imageUri = imageUpload.payload;
          if (item?.imageUrl) {
            thunkDispatch(deleteFile(item.imageUrl));
          }
        }
        await thunkDispatch(
          updateItem({
            storeId,
            imageUrl: imageUri,
            id: itemId,
            ...values,
          })
        );
        history.goBack();
      } catch (error) {
        console.log(error);
        // TODO: handle
      }
    },
    [thunkDispatch, history, storeId, itemId, image, item]
  );

  const handleImageSelect = useCallback((file) => {
    if (file.size > 15 * 1024) {
      return "Image size should be less then 15kb";
    }

    setImage(file);
    return null;
  }, []);

  if (!item) {
    return <Redirect to={`/stores/${storeId}/catalog`} />;
  }

  return (
    <ItemForm
      title="Edit Item"
      subtitle={`Editing ${item?.name}`}
      defaultValues={item}
      onImageSelect={handleImageSelect}
      onFinish={handleFinish}
    />
  );
};

export default ItemEdit;

import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Theme,
  Container,
} from "@material-ui/core";
import Store from "typing/Store";
import {
  BackButton,
  ImagePicker,
  LocationPicker,
  TimePicker,
  Dialog,
} from "components";
import _ from "lodash";
import { useDialog } from "components/Dialog/Dialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

interface StoreFormProps {
  title: string;
  subtitle: string;
  defaultValues?: Store;
  onFinish: (values: Store) => Promise<void>;
  onImageSelect: (file: Blob) => string | null;
  goToUserLocation: boolean;
  okText: string;
}

const StoreForm: React.FC<StoreFormProps> = ({
  title,
  subtitle,
  defaultValues,
  onFinish,
  onImageSelect,
  goToUserLocation,
  okText,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const dialog = useDialog();

  const { register, errors, trigger, getValues } = useForm({
    defaultValues,
  });

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);
      if (await trigger()) {
        const values = getValues() as any;
        await onFinish({
          ...values,
          featured: Number(values.featured),
          l: _.get(values, "l").split(",").map(Number),
        });
      }

      if (errors["l"]) {
        dialog.show({
          title: "Store Location",
          message: "Please click on map to select store location",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [trigger, getValues, onFinish, errors, dialog]);

  const handleImageSelect = useCallback(
    (image) => {
      const result = onImageSelect(image);
      if (result !== null) {
        dialog.show({ title: "Error", message: result });
      }
      return result === null;
    },
    [dialog, onImageSelect]
  );

  const hasError = (fieldName: string) => !!_.get(errors, fieldName);

  return (
    <Container maxWidth="md" className={classes.root}>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader title={title} subheader={subtitle} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid
                item
                md={6}
                xs={12}
                container
                alignItems="center"
                justify="center"
              >
                <ImagePicker
                  defaultImage={_.get(defaultValues, "imageUrl")}
                  onSelect={handleImageSelect}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <LocationPicker
                  height={300}
                  width="100%"
                  defaultValue={_.get(defaultValues, "l", []).join(",")}
                  ref={register({ required: true })}
                  name="l"
                  goToUserLocation={goToUserLocation}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the store name"
                  label="Store name"
                  margin="dense"
                  name="name"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={hasError("name")}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Featured"
                  margin="dense"
                  name="featured"
                  error={hasError("featured")}
                  inputRef={register({ required: true })}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  {_.range(1, 10).map((feature) => (
                    <option key={`feature-${feature}`} value={feature}>
                      {feature}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Location"
                  margin="dense"
                  name="location"
                  error={hasError("location")}
                  inputRef={register({ required: true })}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  margin="dense"
                  name="desc"
                  error={hasError("desc")}
                  inputRef={register({ required: true })}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  margin="dense"
                  name="phone"
                  error={hasError("phone")}
                  type="tel"
                  inputRef={register({ required: true, pattern: /[0-9]{10}/ })}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs="auto" />

              <Grid item md={6} xs={12}>
                <TimePicker
                  name="openTime"
                  label="Open Time"
                  format="hh:mm A"
                  defaultValue={_.get(defaultValues, "openTime")}
                  ref={register({ required: true })}
                  error={hasError("openTime")}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TimePicker
                  name="closeTime"
                  label="Close Time"
                  format="hh:mm A"
                  defaultValue={_.get(defaultValues, "closeTime")}
                  ref={register({ required: true })}
                  error={hasError("closeTime")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleFinish}
              disabled={loading}
            >
              {okText}
            </Button>
            <BackButton>Cancel</BackButton>
          </CardActions>
        </form>
      </Card>
      <Dialog {...dialog.props} />
    </Container>
  );
};

export default StoreForm;

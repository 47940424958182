import { useSelector } from "react-redux";
import { AppState } from "..";
import { UserLevel } from "typing/User";

export const useAuth = () => useSelector((state: AppState) => state.auth);

export const useUserLevel = () => {
  const auth = useAuth();

  const level = auth.user?.level;

  return {
    level,
    isAdmin: level === UserLevel.ADMIN,
    isOwner: level === UserLevel.OWNER,
  };
};

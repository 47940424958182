import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { StatusBullet } from "components";
import { Status } from "typing/Order";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: 440,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
}));

const OrderStatusBullet = ({ status }: { status: Status }) => {
  const classes = useStyles();

  return (
    <div className={classes.statusContainer}>
      <StatusBullet
        className={classes.status}
        color={getOrderStatusType(status)}
        size="sm"
      />
      {getOrderStatusText(status)}
    </div>
  );
};

function getOrderStatusType(status: Status) {
  if (status === Status.PLACED) {
    return "primary";
  } else if (status === Status.PICKED_UP || status === Status.DELIVERED) {
    return "success";
  } else if (status === Status.CANCELLED) {
    return "danger";
  }
  return "info";
}

function getOrderStatusText(status: Status) {
  if (status === Status.PLACED) {
    return "Placed";
  } else if (status === Status.PICKED_UP) {
    return "Picked up";
  } else if (status === Status.CONFIRMED) {
    return "Confirmed";
  } else if (status === Status.CANCELLED) {
    return "Cancelled";
  } else if (status === Status.DELIVERED) {
    return "Delivered";
  }
  return "Status";
}

export default OrderStatusBullet;

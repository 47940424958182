import React, { /* useState, */ useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import { useDispatch } from "react-redux";
import { startSignOut } from "../../../../store/auth/actions";
import { useAuth } from "store/auth/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  title: {
    color: theme.palette.white,
  },
  flexGrow: {
    flexGrow: 1,
  },
  openAppButton: {
    color: "white",
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleSignOut = useCallback(() => {
    dispatch(startSignOut());
  }, [dispatch]);

  const auth = useAuth();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <Typography className={classes.title} variant="h3">
            Expressify
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />

        <Button
          variant="outlined"
          className={classes.openAppButton}
          onClick={() => {
            window.open(`https://app.expressify.one/${auth.user?.businessId}`);
          }}
        >
          Open App
        </Button>

        <Hidden mdDown>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleSignOut}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;

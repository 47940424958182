import { takeLeading, put, takeEvery, take, select } from "redux-saga/effects";
import { subscribeToStoreList, unsubscribeToStoreList } from "./actions";
import { eventChannel } from "redux-saga";
import { Refs, selectRefs } from "config/Firebase";
import { storeActions } from "./slice";
import { getListFromSnapshot } from "utils/firebase";
import Store from "typing/Store";
import { AppState } from "store";

export function* storeListSubscriber() {
  yield takeLeading(subscribeToStoreList.type, listen);
}

function* listen() {
  const zoneId = yield select((state: AppState) => state.auth.user?.zoneId);

  const refs: Refs = yield selectRefs();

  const channel = eventChannel((emit) => {
    const ref = refs.store.orderByChild("zoneId").equalTo(zoneId);

    ref.on("value", (snapshot) => {
      emit({ snapshot });
    });

    return () => {
      ref.off();
    };
  });

  yield takeEvery(channel, function* ({ snapshot }) {
    const list = getListFromSnapshot<Store>(snapshot);
    yield put(storeActions.patchList(list));
  });

  yield take(unsubscribeToStoreList.type);
  return channel.close();
}

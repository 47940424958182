import { useSelector } from "react-redux";
import { AppState } from "store";
import { useMemo } from "react";
import { filterArrayBySearch } from "utils/utils";
import { UserLevel } from "typing/User";
import Dex from "typing/Dex";

export const useExeListLoading = () =>
  useSelector((state: AppState) => state.user.exeListLoading);

export const useAdminListLoading = () =>
  useSelector((state: AppState) => state.user.adminListLoading);

export const useExeList = () =>
  useSelector((state: AppState) => state.user.exeList);

export const useDexList = () => {
  const list = useExeList();
  return list.filter((it) => it.level === UserLevel.DEX);
};

export const useAdminList = () =>
  useSelector((state: AppState) => state.user.adminList);

export const useAdminItem = (id: string) => {
  const list = useAdminList();
  return useMemo(() => list.find((it) => it.id === id), [list, id]);
};

export const useUserItem = (id: string) => {
  const list = useExeList();
  return useMemo(() => list.find((it) => it.id === id), [list, id]);
};

const useUserSearchText = () =>
  useSelector((appState: AppState) => appState.user.searchText);

export const useFilteredDex = () => {
  const searchText = useUserSearchText();
  const list = useDexList() as Dex[];
  return useMemo(() => filterArrayBySearch(list, searchText, "name"), [
    list,
    searchText,
  ]);
};

export const useFilteredAdmin = () => {
  const searchText = useUserSearchText();
  const list = useAdminList();
  return useMemo(() => filterArrayBySearch(list, searchText, "name"), [
    list,
    searchText,
  ]);
};

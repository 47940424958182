import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Theme,
  CardHeader,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { WithLoading, TimePicker, DatePicker } from "components";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import AddIcon from "@material-ui/icons/Add";
import Coupon, { CouponType } from "typing/Coupon";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import {
  subscribeToCouponList,
  unsubscribeFromCouponList,
  addCoupon,
} from "store/coupon/actions";
import { useCouponList, useCouponListLoading } from "store/coupon/selectors";
import moment from "moment";
import * as codes from "voucher-code-generator";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(4),
    minHeight: 150,
  },
  noCoupons: {
    padding: theme.spacing(2),
  },
}));

const CouponList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [targetCoupon, setTargetCoupon] = useState<Coupon | undefined>(
    undefined
  );
  const loading = useCouponListLoading();
  const list = useCouponList();

  useEffect(() => {
    dispatch(subscribeToCouponList());
    return () => {
      dispatch(unsubscribeFromCouponList());
    };
  }, [dispatch]);

  const handleClose = useCallback(
    (coupon?: Coupon) => {
      setDialogOpen(false);
      setTargetCoupon(undefined);

      if (coupon) {
        dispatch(
          addCoupon({
            ...coupon,
            maxDiscount: Number(coupon.maxDiscount),
            minOrderValue: Number(coupon.minOrderValue),
            discountPercent: Number(coupon.discountPercent),
            usageLimit: Number(coupon.usageLimit),
          })
        );
      }
    },
    [dispatch]
  );

  const handleCouponView = useCallback(
    (coupon) => () => {
      setDialogOpen(true);
      setTargetCoupon(coupon);
    },
    []
  );

  return (
    <Card className={classes.root}>
      <CardHeader title="Coupons" />
      <CardContent>
        <WithLoading loading={loading}>
          <List component="nav" aria-label="main mailbox folders">
            {_.isEmpty(list) ? (
              <Typography className={classes.noCoupons} align="center">
                No coupons
              </Typography>
            ) : (
              list.map((it) => (
                <ListItem key={it.code} button>
                  <ListItemIcon>
                    <LocalOfferIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${it.name} (${it.code})`}
                    secondary={`Type: ${it.type} • Expires at ${moment(
                      it.expiresAt
                    ).format("DD MMM YY")} • Usage Limit: ${
                      it.usageLimit
                    } • Available: ${it.availStartTime} - ${
                      it.availEndTime
                    } • Max discount: ${it.maxDiscount} • Discount: ${
                      it.discountPercent
                    }%`}
                  />
                </ListItem>
              ))
            )}
            <ListItem
              button
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Create new coupon" />
            </ListItem>
          </List>
        </WithLoading>
      </CardContent>

      <CouponDialog open={dialogOpen} onClose={handleClose} />
    </Card>
  );
};

function CouponDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (coupon?: Coupon) => void;
}) {
  const { register, trigger, getValues, errors } = useForm();

  const hasError = (fieldName: string) => !!_.get(errors, fieldName);

  const handleClose = () => {
    onClose();
  };

  const handleSave = useCallback(async () => {
    if (await trigger()) {
      const values = getValues() as any;
      onClose(values);
    }
  }, [trigger, getValues, onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create new coupon</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              helperText="Please specify the coupon code"
              label="Coupon code"
              margin="dense"
              name="code"
              variant="outlined"
              inputRef={register({ required: true })}
              error={hasError("name")}
              defaultValue={codes.generate({ length: 8, count: 1 })[0]}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              helperText="Please specify the coupon name"
              label="Coupon name"
              margin="dense"
              name="name"
              variant="outlined"
              inputRef={register({ required: true })}
              error={hasError("name")}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <DatePicker
              name="validFrom"
              label="Valid From"
              format="YYYY-MM-DD"
              ref={register({ required: true })}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <DatePicker
              name="expiresAt"
              label="Expires At"
              format="YYYY-MM-DD"
              ref={register({ required: true })}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Select Type"
              margin="dense"
              name="type"
              error={hasError("type")}
              inputRef={register({ required: true })}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option value={CouponType.DISCOUNT}>Discount</option>
              <option value={CouponType.FREE_DELIVERY}>Free delivery</option>
            </TextField>
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Discount Percent"
              margin="dense"
              name="discountPercent"
              error={hasError("discountPercent")}
              type="number"
              inputRef={register({ required: true })}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Max Discount"
              margin="dense"
              name="maxDiscount"
              error={hasError("maxDiscount")}
              type="number"
              inputRef={register({ required: true })}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Minimum Order Value"
              margin="dense"
              name="minOrderValue"
              error={hasError("minOrderValue")}
              type="number"
              inputRef={register({ required: true })}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TimePicker
              name="availStartTime"
              label="Avail Start Time"
              defaultValue={"00:00"}
              ref={register({ required: true })}
              error={hasError("availStartTime")}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TimePicker
              name="availEndTime"
              label="Avail End Time"
              defaultValue={"23:59"}
              ref={register({ required: true })}
              error={hasError("availEndTime")}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Usage Limit"
              margin="dense"
              name="usageLimit"
              error={hasError("usageLimit")}
              type="number"
              inputRef={register({ required: true })}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={false}
                  inputRef={register}
                  name="newUsersOnly"
                />
              }
              label="New Users Only"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose()}>
          Cancel
        </Button>

        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CouponList;

import React, { useState, useCallback, useMemo } from "react";
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
  DatePickerProps,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

interface Props extends Partial<DatePickerProps> {
  name: string;
  label: string;
  defaultValue?: string;
  error?: boolean;
  format?: string;
}

const TimePicker = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      name,
      error,
      format = "DDMMYYYY",
      defaultValue = moment().format(format),
      ...restProps
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);

    const dateValue = useMemo(() => moment(value, format), [value, format]);

    const handleChange = useCallback(
      (time) => setValue(moment(time).format(format)),
      [format]
    );

    return (
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <MuiDatePicker
          {...restProps}
          fullWidth
          name={name}
          margin="normal"
          label={label}
          format={format}
          value={dateValue}
          onChange={handleChange}
          error={error}
          inputVariant="outlined"
          inputRef={ref}
        />
      </MuiPickersUtilsProvider>
    );
  }
);

export default TimePicker;

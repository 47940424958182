import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Theme,
  Button,
} from "@material-ui/core";

import usePagination from "hooks/Pagination";

import Category from "typing/Category";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteCategory } from "store/category/actions";
import { Dialog } from "components";
import { useDialog } from "components/Dialog/Dialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const CategoryListTable: React.FC<{
  storeId: string;
  categoryList: Array<Category>;
}> = ({ storeId, categoryList }) => {
  const classes = useStyles();

  const {
    startAt,
    endAt,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
  } = usePagination();

  const dispatch = useDispatch();

  const [deletingCategory, setDeletingCategory] = useState<Category | null>(
    null
  );

  const dialog = useDialog(
    useCallback(
      (positive) => {
        if (positive) {
          dispatch(deleteCategory({ id: deletingCategory!.id, storeId }));
        }
      },
      [deletingCategory, storeId, dispatch]
    )
  );

  const handleDelete = useCallback(
    (category) => () => {
      setDeletingCategory(category);
      dialog.show({
        title: "Are you sure?",
        message: "You want to delete this category",
      });
    },
    [dialog]
  );

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryList.slice(startAt, endAt).map((category, index) => (
                  <TableRow hover key={category.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="text"
                        size="small"
                        component={Link}
                        to={`/stores/${storeId}/categories/${category.id}/edit`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="text"
                        size="small"
                        onClick={handleDelete(category)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={categoryList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <Dialog {...dialog.props} showNegativeButton={true} />
    </Card>
  );
};

CategoryListTable.propTypes = {
  categoryList: PropTypes.array.isRequired,
};

export default CategoryListTable;

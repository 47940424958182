import { useState, useMemo, useCallback } from "react";

const usePagination = (
  initialPage: number = 0,
  initialRowsPerPage: number = 10
) => {
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [page, setPage] = useState(initialPage);

  const startAt = useMemo(() => page * rowsPerPage, [page, rowsPerPage]);
  const endAt = useMemo(() => startAt + rowsPerPage, [rowsPerPage, startAt]);

  const handlePageChange = useCallback((_, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  return {
    startAt,
    endAt,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
  };
};

export default usePagination;

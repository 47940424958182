import { takeLeading, put, takeEvery, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { getListFromSnapshot } from "utils/firebase";
import { subscribeToStats, unsubscribeFromStats } from "./actions";
import moment from "moment";
import { Refs, selectRefs } from "config/Firebase";
import { statsActions, Stat } from "./slice";
import _ from "lodash";
import { DAILY_FORMAT, MONTHLY_FORMAT } from "appContants";

export function* statsSubscriber() {
  yield takeLeading(subscribeToStats.type, listen);
}

function* listen() {
  const refs: Refs = yield selectRefs();

  const channel = eventChannel((emit) => {
    const now = moment();
    const today = now.format(DAILY_FORMAT);
    const thisMonth = now.format(MONTHLY_FORMAT);
    const lastMonth = moment().subtract(1, "months").format(MONTHLY_FORMAT);
    const weekAgo = moment().subtract(6, "days").format(DAILY_FORMAT);

    const monthlyOrderRef = refs.monthlyOrderCountStats
      .orderByKey()
      .startAt(lastMonth)
      .endAt(thisMonth);

    const dailyOrderRef = refs.dailyOrderCountStats
      .orderByKey()
      .startAt(weekAgo)
      .endAt(today);

    const monthlyEarningRef = refs.monthlyEarningStats
      .orderByKey()
      .startAt(lastMonth)
      .endAt(thisMonth);

    const monthlyUserCountRef = refs.monthlyUserCountStats
      .orderByKey()
      .startAt(lastMonth)
      .endAt(thisMonth);

    monthlyOrderRef.on("value", (monthlyOrderSnapshot) => {
      emit({ monthlyOrderSnapshot });
    });

    dailyOrderRef.on("value", (dailyOrderSnapshot) => {
      emit({ dailyOrderSnapshot });
    });

    monthlyEarningRef.on("value", (monthlyEarningSnapshot) => {
      emit({ monthlyEarningSnapshot });
    });

    monthlyUserCountRef.on("value", (monthlyUserCountSnapshot) => {
      emit({ monthlyUserCountSnapshot });
    });

    return () => {
      monthlyOrderRef.off();
      dailyOrderRef.off();
      monthlyEarningRef.off();
    };
  });

  yield takeEvery(channel, function* ({
    monthlyOrderSnapshot,
    dailyOrderSnapshot,
    monthlyEarningSnapshot,
    monthlyUserCountSnapshot,
  }) {
    const monthlyStats = makeStats(2, (i) =>
      moment().subtract(i, "months").format(MONTHLY_FORMAT)
    );

    const dailyStats = makeStats(7, (i) =>
      moment().subtract(i, "days").format(DAILY_FORMAT)
    );

    if (monthlyOrderSnapshot) {
      const data = _.unionBy(
        getListFromSnapshot(monthlyOrderSnapshot),
        monthlyStats,
        "id"
      );

      yield put(statsActions.setMonthlyOrderStats(data));
    }

    if (dailyOrderSnapshot) {
      const data = _.unionBy(
        getListFromSnapshot(dailyOrderSnapshot),
        dailyStats,
        "id"
      );

      yield put(statsActions.setDailyOrderStats(data));
    }

    if (monthlyEarningSnapshot) {
      const data = _.unionBy(
        getListFromSnapshot(monthlyEarningSnapshot),
        monthlyStats,
        "id"
      );

      yield put(statsActions.setMonthlyEarningStats(data));
    }

    if (monthlyUserCountSnapshot) {
      yield put(
        statsActions.setMonthlyUserStats(monthlyUserCountSnapshot.val())
      );
    }
  });

  yield take(unsubscribeFromStats.type);
  return channel.close();
}

const makeStats = (until: number, makeId: (i: number) => string) =>
  _.range(0, until).map<Stat>((i) => ({
    id: makeId(i),
    active: 0,
    delivered: 0,
    cancelled: 0,
    total: 0,
  }));

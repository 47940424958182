import { useSelector } from "react-redux";
import { AppState } from "store";
import moment from "moment";
import { MONTHLY_FORMAT, DAILY_FORMAT } from "appContants";
import { Stat } from "./slice";

const defaultStat = (): Stat => {
  return {
    id: Date.toString(),
    active: 0,
    delivered: 0,
    cancelled: 0,
    total: 0,
  };
};

export const useStats = () => useSelector((state: AppState) => state.stats);

export const useMonthlyOrderStats = () =>
  useSelector((state: AppState) => state.stats.monthlyOrderStats);

export const useThisMonthOrderStat = () => {
  const stats = useSelector((state: AppState) => state.stats.monthlyOrderStats);
  return (
    stats.find(({ id }) => moment().format(MONTHLY_FORMAT) === id) ??
    defaultStat()
  );
};

export const useLastMonthOrderStat = () => {
  const stats = useSelector((state: AppState) => state.stats.monthlyOrderStats);
  return (
    stats.find(
      ({ id }) => moment().subtract(1, "months").format(MONTHLY_FORMAT) === id
    ) ?? defaultStat()
  );
};

export const useThisMonthEarningStat = () => {
  const stats = useSelector(
    (state: AppState) => state.stats.monthlyEarningStats
  );
  return (
    stats.find(({ id }) => moment().format(MONTHLY_FORMAT) === id) ??
    defaultStat()
  );
};

export const useLastMonthEarningStat = () => {
  const stats = useSelector(
    (state: AppState) => state.stats.monthlyEarningStats
  );
  return (
    stats.find(
      ({ id }) => moment().subtract(1, "months").format(MONTHLY_FORMAT) === id
    ) ?? defaultStat()
  );
};

export const useDailyOrderStats = () =>
  useSelector((state: AppState) => state.stats.dailyOrderStats);

export const useMonthlyEarningStats = () =>
  useSelector((state: AppState) => state.stats.monthlyEarningStats);

export const useTodayOrderStat = () => {
  const stats = useDailyOrderStats();
  return (
    stats.find(({ id }) => moment().format(DAILY_FORMAT) === id) ??
    defaultStat()
  );
};

import React, { useState, useCallback } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

interface Props {
  title: string;
  message: string;
  open: boolean;
  onClose: (positive: boolean) => void;
  showNegativeButton?: boolean;
  cancelText?: string;
}

const Dialog: React.FC<Props> = ({
  title,
  message,
  open,
  onClose,
  showNegativeButton,
  cancelText,
}) => {
  const handleClose = useCallback(
    (positive) => () => {
      onClose(positive);
    },
    [onClose]
  );

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {showNegativeButton && (
          <Button onClick={handleClose(false)} color="primary">
            {cancelText ?? "Cancel"}
          </Button>
        )}
        <Button onClick={handleClose(true)} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export const useDialog = (onClose?: (positive: boolean) => void) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(
    (positive: boolean) => {
      setOpen(false);
      onClose && onClose(positive);
    },
    [onClose]
  );

  const show = useCallback(
    ({ title, message }: Pick<Props, "title" | "message">) => {
      setTitle(title);
      setMessage(message);
      setOpen(true);
    },
    []
  );

  return {
    show,
    props: {
      title,
      message,
      open,
      onClose: handleClose,
    },
  };
};

export default Dialog;

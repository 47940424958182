import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import { Status } from "typing/Order";

const STATUS_LIST = [
  {
    title: "Placed",
    value: Status.PLACED,
  },
  {
    title: "Confirmed",
    value: Status.CONFIRMED,
  },
  {
    title: "Picked Up",
    value: Status.PICKED_UP,
  },
  {
    title: "Deliveried",
    value: Status.DELIVERED,
  },
  {
    title: "Cancelled",
    value: Status.CANCELLED,
  },
];

export interface OrderStatusDialogProps {
  id: string;
  keepMounted: boolean;
  value: Status;
  open: boolean;
  onClose: (value?: Status) => void;
}

export default function OrderStatusDialog(props: OrderStatusDialogProps) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event: any) => {
    setValue(Number(event.target.value));
  };

  return (
    <Dialog
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Update Order Status
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="Order Status"
          name="status"
          value={value}
          onChange={handleChange}
        >
          {STATUS_LIST.map(({ title, value }) => (
            <FormControlLabel
              style={{ width: 200 }}
              value={value}
              key={title}
              control={<Radio />}
              label={title}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

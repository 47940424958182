import { ServerTimestamp, trashRef } from "config/Firebase";

export async function addRefToTrash(ref: firebase.database.Reference) {
  const path = getPathFromRef(ref);
  const decodedPath = decodePathForTrash(path);
  await trashRef.child(decodedPath).set(ServerTimestamp);
}

export function decodePathForTrash(path: string) {
  return path.replace(/\//g, ",");
}

export function encdoePathForTrash(path: string) {
  return path.replace(/,/g, "/");
}

export function getPathFromRef(ref: firebase.database.Reference) {
  return ref.toString().substring(ref.root.toString().length - 1);
}

export const getListFromSnapshot = <T>(
  snapshot: firebase.database.DataSnapshot
): T[] => {
  if (!snapshot.exists()) {
    return [];
  }
  return Object.entries<T>(snapshot.val()).map(([id, value]) => ({
    id,
    ...value,
  }));
};

export const getObjectFromSnapshot = <T>(
  snapshot: firebase.database.DataSnapshot
): T | undefined => {
  if (snapshot.exists()) {
    return snapshot.val() as T;
  }
  return undefined;
};

export const getObjectFromDocumentChange = <T>(
  change: firebase.firestore.DocumentChange
) => {
  return { ...change.doc.data() } as T;
};

export const getObjectFromDocument = <T>(
  document: firebase.firestore.DocumentSnapshot
) => {
  return { ...document.data() } as T;
};

export const getArrayFromQuerySnapshot = <T extends { id: string }>(
  snapshot: firebase.firestore.QuerySnapshot
): T[] => {
  if (snapshot.size === 0) {
    return [];
  }

  return snapshot.docs.map((d) => ({ id: d.id, ...d.data() } as T));
};

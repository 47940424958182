import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  TextField,
  Divider,
  Theme,
  Box,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { sendPushNotification } from "store/actions";
import { useThunkDispatch } from "store";
import { Alert } from "components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(4),
    minHeight: 300,
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));

const PushNotifications = () => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const [response, setResponse] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { register, trigger, getValues, errors } = useForm();

  const handleSend = useCallback(async () => {
    try {
      setLoading(true);

      if (await trigger()) {
        await dispatch(sendPushNotification(getValues() as any));
      }
      setResponse({
        success: true,
        message: "Push notifications sent",
      });
    } finally {
      setResponse({
        success: false,
        message: "Failed to send. Please try again after sometime.",
      });
      setLoading(false);
    }
  }, [dispatch, getValues, trigger]);

  return (
    <Card className={classes.root}>
      <CardHeader
        subheader="Keep your customer engaged by sending push notifications"
        title="Push Notifications"
      />
      <Divider />
      <CardContent>
        {response && (
          <React.Fragment>
            <Alert
              severity={
                !response.loading && response.success ? "success" : "error"
              }
              onClose={() => setResponse(null)}
            >
              {response.message}
            </Alert>
            <Box height={20} />
          </React.Fragment>
        )}

        <TextField
          className={classes.item}
          name="title"
          inputRef={register({ required: true })}
          fullWidth
          variant="outlined"
          label="Notification Title"
          placeholder="Type title"
          helperText="Please provide notification title"
          error={!!errors.title}
        />
        <TextField
          className={classes.item}
          name="message"
          inputRef={register({ required: true })}
          fullWidth
          variant="outlined"
          label="Notification Message"
          multiline
          rows={4}
          helperText="Please provide notification message"
          placeholder="Type message"
          error={!!errors.message}
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSend}
          disabled={loading}
        >
          Send
        </Button>
      </CardActions>
    </Card>
  );
};

export default PushNotifications;

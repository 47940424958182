import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Theme,
  Container,
} from "@material-ui/core";
import { BackButton, ImagePicker, Dialog } from "components";
import _ from "lodash";
import Item, { ItemFeature } from "typing/Item";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useCategoryList } from "store/category/selectors";
import { useParams } from "react-router-dom";
import { useDialog } from "components/Dialog/Dialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

interface ItemFormProps {
  title: string;
  subtitle: string;
  defaultValues?: Item;
  onFinish: (values: Item) => Promise<void>;
  onImageSelect: (file: Blob) => string | null;
}

const ItemForm: React.FC<ItemFormProps> = ({
  title,
  subtitle,
  defaultValues,
  onFinish,
  onImageSelect,
}) => {
  const { storeId } = useParams<{ storeId: string }>();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();

  const { register, errors, trigger, getValues } = useForm({
    defaultValues,
  });

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);
      if (await trigger()) {
        const values = getValues();

        await onFinish({
          ...values,
          price: Number(values.price),
          featured: Number(values.featured),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [trigger, getValues, onFinish]);

  const handleImageSelect = useCallback(
    (image) => {
      const result = onImageSelect(image);
      if (result !== null) {
        dialog.show({ title: "Error", message: result });
      }
      return result === null;
    },
    [dialog, onImageSelect]
  );

  const hasError = (fieldName: string) => !!_.get(errors, fieldName);

  const categoryList = useCategoryList(storeId);

  return (
    <Container maxWidth="md" className={classes.root}>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader title={title} subheader={subtitle} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid
                item
                md={6}
                xs={12}
                container
                justify="center"
                alignItems="center"
              >
                <ImagePicker
                  defaultImage={_.get(defaultValues, "imageUrl")}
                  onSelect={handleImageSelect}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the name"
                  label="Name"
                  margin="dense"
                  name="name"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={hasError("name")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={_.get(defaultValues, "veg", false)}
                      inputRef={register}
                      name="veg"
                    />
                  }
                  label="Pure Veg"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Category"
                  margin="dense"
                  name="categoryId"
                  error={hasError("categoryId")}
                  inputRef={register({ required: true })}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  {categoryList.list.map((c) => (
                    <option value={c.id} key={c.id}>
                      {c.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Featured"
                  margin="dense"
                  name="featured"
                  error={hasError("featured")}
                  inputRef={register({ required: true })}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  <option value={ItemFeature.NONE}>None</option>
                  <option value={ItemFeature.BEST_SELLER}>Bestseller</option>
                  <option value={ItemFeature.ONLY_HERE}>Only Here</option>
                  <option value={ItemFeature.TOP_PICK}>Top Pick</option>
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Price"
                  margin="dense"
                  name="price"
                  type="number"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={hasError("price")}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Tags"
                  margin="dense"
                  name="tags"
                  variant="outlined"
                  inputRef={register}
                  error={hasError("tags")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleFinish}
              disabled={loading}
            >
              Save
            </Button>
            <BackButton>Cancel</BackButton>
          </CardActions>
        </form>
      </Card>
      <Dialog {...dialog.props} />
    </Container>
  );
};

export default ItemForm;

import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import Category from "typing/Category";

interface CategoryListState {
  searchText: string;
  data: {
    [storeId: string]: {
      list: Category[];
      loading: boolean;
    };
  };
}

const categoryListSlice = createSlice({
  name: "categoryList",
  initialState: {
    searchText: "",
    data: {},
  } as CategoryListState,
  reducers: {
    patchList: (state, action) => {
      const storeId = action.payload.storeId;
      const list = action.payload.list;
      if (state.data[storeId]) {
        const oldList = state.data[storeId].list;
        state.data[storeId].list = _.unionBy(list, oldList, "id");
      } else {
        state.data[storeId] = {
          loading: false,
          list,
        };
      }
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
});

const { actions, reducer } = categoryListSlice;

export { actions as categoryListActions, reducer as categoryListReducer };

import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useThunkDispatch } from "store";
import { updateUser } from "store/user/actions";
import { useAuth } from "store/auth/selectors";
import _ from "lodash";
import { authActions } from "store/auth/slice";
import { useDispatch } from "react-redux";
import { Dialog } from "components";
import { useDialog } from "components/Dialog/Dialog";

const useStyles = makeStyles(() => ({
  root: {},
}));

const AccountDetails = () => {
  const classes = useStyles();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const auth = useAuth();
  const dialog = useDialog();

  const { register, trigger, getValues } = useForm({
    defaultValues: auth.user,
  });

  const handleSave = useCallback(async () => {
    if (await trigger()) {
      const values = getValues();

      const password = _.get(values, "password", null);
      const isPasswordSet = !_.isEmpty(_.trim(password));

      const action = await thunkDispatch(
        updateUser({
          ...values,
          ...(isPasswordSet && { password }),
          id: auth.user!.id,
        })
      );

      if (action.type === updateUser.rejected.toString()) {
        dialog.show({ title: "Error", message: action.payload });
      } else {
        dispatch(authActions.setUser({ ...auth.user, ...action.payload.data }));
      }
    }
  }, [trigger, getValues, dispatch, thunkDispatch, auth, dialog]);

  return (
    <Card className={classes.root}>
      <form autoComplete="off" noValidate>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="Name"
                margin="dense"
                name="name"
                variant="outlined"
                inputRef={register({ required: true })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                variant="outlined"
                inputRef={register({ required: true })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                variant="outlined"
                inputRef={register({ required: true })}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Update Password"
                margin="dense"
                name="password"
                type="text"
                variant="outlined"
                inputRef={register}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="contained" onClick={handleSave}>
            Save details
          </Button>
        </CardActions>
      </form>
      <Dialog {...dialog.props} />
    </Card>
  );
};

export default AccountDetails;

import React, { useEffect } from "react";
import UserList from "../UserList";
import { useFilteredAdmin, useAdminListLoading } from "store/user/selectors";
import { useDispatch } from "react-redux";
import { subscribeToAdminList } from "store/user/actions";

const AdminList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToAdminList());
  }, [dispatch]);

  const list = useFilteredAdmin();

  const loading = useAdminListLoading();

  return (
    <UserList
      list={list}
      makeEditUrl={(it) => `admins/${it.id}/edit`}
      addText="Add Admin"
      addUrl="admins/add"
      searchHint="Search admin"
      loading={loading}
    />
  );
};

export default AdminList;

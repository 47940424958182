import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import User from "typing/User";
import { unsubscribeFromExeList } from "./actions";

interface State {
  searchText: string;
  exeList: User[];
  adminList: User[];
  adminListLoading: boolean;
  exeListLoading: boolean;
}

const userSlice = createSlice({
  name: "user",
  initialState: {
    searchText: "",
    exeList: [],
    adminList: [],
    exeListLoading: true,
    adminListLoading: true,
    maxUpdatedAt: 0,
  } as State,
  reducers: {
    patchExeList: (state, action) => {
      state.exeList = _.unionBy(action.payload, state.exeList, "id");
      state.exeListLoading = false;
    },
    patchAdminList: (state, action) => {
      state.adminList = _.unionBy(action.payload, state.adminList, "id");
      state.adminListLoading = false;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(unsubscribeFromExeList.type, (state) => {
      state.exeList = [];
      state.adminList = [];
      state.exeListLoading = true;
      state.adminListLoading = true;
      state.searchText = "";
    });
  },
});

const { actions, reducer } = userSlice;

export { actions as userActions, reducer as userReducer };

import React, { useCallback } from "react";
import CategoryForm from "../CategoryForm/CategoryForm";
import { useThunkDispatch } from "store";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { updateCategory } from "store/category/actions";
import { useCategoryItem } from "store/category/selectors";

const CategoryEdit: React.FC = () => {
  const thunkDispatch = useThunkDispatch();

  const history = useHistory();
  const { storeId, categoryId } = useParams<{
    storeId: string;
    categoryId: string;
  }>();

  const category = useCategoryItem(storeId, categoryId);

  const handleFinish = useCallback(
    async (values) => {
      try {
        await thunkDispatch(
          updateCategory({
            storeId,
            id: categoryId,
            ...values,
          })
        );
        history.goBack();
      } catch (error) {
        console.log(error);
        // TODO: handle
      }
    },
    [thunkDispatch, history, storeId, categoryId]
  );

  if (!category) {
    return <Redirect to={`/stores/${storeId}/catalog`} />;
  }

  return (
    <CategoryForm
      title="Edit Category"
      subtitle={`Editing ${category?.name} category`}
      defaultValues={category}
      onFinish={handleFinish}
    />
  );
};

export default CategoryEdit;

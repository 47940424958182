import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
} from "@material-ui/core";
import { WithLoading } from "components";
import { useConfig, useConfigLoading } from "store/settings/selectors";
import _ from "lodash";
import { useDispatch } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import { Helpline } from "typing/BusinessConfig";
import { useForm } from "react-hook-form";
import {
  updateHelpline,
  addHelpline,
  removeHelpline,
} from "store/settings/thunks";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 300,
  },
}));

const Helplines = () => {
  const classes = useStyles();
  const loading = useConfigLoading();
  const config = useConfig();
  const dispatch = useDispatch();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingHelpline, setEditinHelpline] = useState<Helpline | null>(null);

  const handleUpdate = useCallback(
    (helpline?: Helpline) => {
      setEditDialogOpen(false);

      if (helpline) {
        if (editingHelpline?.id) {
          dispatch(updateHelpline(helpline));
        } else {
          dispatch(addHelpline(helpline));
        }
      }
    },
    [dispatch, editingHelpline]
  );

  const handleEdit = useCallback(
    (helpline: Helpline) => () => {
      setEditDialogOpen(true);
      setEditinHelpline(helpline);
    },
    []
  );

  const handleDelete = useCallback(
    (helpline) => () => {
      dispatch(removeHelpline(helpline));
    },
    [dispatch]
  );

  const helplines = _.entries(
    config?.helplines ?? {}
  ).map(([id, helpline]) => ({ ...helpline, id }));

  return (
    <Card className={classes.root}>
      <WithLoading loading={loading}>
        <List component="nav" aria-label="main mailbox folders">
          {helplines.map((it) => (
            <ListItem key={it.id} button>
              <ListItemIcon>
                <LiveHelpIcon />
              </ListItemIcon>
              <ListItemText primary={it.title} secondary={it.phoneNumber} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={handleEdit(it)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={handleDelete(it)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <ListItem
            autoFocus
            button
            onClick={() => {
              setEditDialogOpen(true);
              setEditinHelpline({ title: "", phoneNumber: "" });
            }}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Add helpline" />
          </ListItem>
        </List>
      </WithLoading>
      {editingHelpline && (
        <HelplineDialog
          open={editDialogOpen}
          helpline={editingHelpline}
          onClose={handleUpdate}
        />
      )}
    </Card>
  );
};

function HelplineDialog({
  helpline,
  open,
  onClose,
}: {
  helpline: Helpline;
  open: boolean;
  onClose: (helpline?: Helpline) => void;
}) {
  const { register, trigger, getValues, errors } = useForm();

  const handleClose = () => {
    onClose();
  };

  const handleSave = useCallback(async () => {
    if (await trigger()) {
      const values = getValues() as any;
      onClose(values);
    }
  }, [trigger, getValues, onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {_.isEmpty(helpline.title) ? "New Helpline" : helpline.title}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          name="title"
          inputRef={register({ required: true })}
          defaultValue={helpline.title}
          error={!!_.get(errors, "title")}
          helperText={!!_.get(errors, "title") ? "Enter helpline title" : ""}
        />
        <TextField
          margin="dense"
          label="Phone Number"
          type="number"
          fullWidth
          name="phoneNumber"
          inputRef={register({ required: true })}
          defaultValue={helpline.phoneNumber}
          error={!!_.get(errors, "phoneNumber")}
          helperText={
            !!_.get(errors, "phoneNumber") ? "Enter valid phone number" : ""
          }
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Helplines;

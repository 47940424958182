import React, { useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  Dashboard as DashboardView,
  Account as AccountView,
  Settings as SettingsView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  ServiceExpired as ServiceExpiredView,
} from "./views";

import StoreListView from "./views/store/StoreList";
import StoreAddView from "./views/store/StoreAdd";
import StoreEditView from "./views/store/StoreEdit";
import StoreCatalogView from "views/store/StoreCatalog";
import CategoryAddView from "views/store/StoreCatalog/components/CategoryAdd";
import CategoryEditView from "views/store/StoreCatalog/components/CategoryEdit";
import ItemAddView from "views/store/StoreCatalog/components/ItemAdd";
import ItemEditView from "views/store/StoreCatalog/components/ItemEdit";
import SlideListView from "./views/slide/SlideList";
import SlideAddView from "./views/slide/SlideAdd";
import SlideEditView from "./views/slide/SlideEdit";
import DexListView from "./views/user/DexList";
import DexAddView from "./views/user/DexAdd";
import DexEditView from "./views/user/DexEdit";
import AdminListView from "./views/user/AdminList";
import AdminAddView from "./views/user/AdminAdd";
import AdminEditView from "./views/user/AdminEdit";
import ZoneListView from "./views/zone/ZoneList";
import ZoneAddView from "./views/zone/ZoneAdd";
import ZoneEditView from "./views/zone/ZoneEdit";
import OrderListView from "./views/order/OrderList";
import CompletedOrderListView from "./views/order/CompletedOrderList";
import OrderDetailsView from "./views/order/OrderDetails";
import CouponListView from "./views/CouponList";
import PushNotifications from "./views/PushNotifications";
import Onboarding from "views/Onboarding/Onboarding";
import { useDispatch } from "react-redux";
import { subscribeToConfig } from "store/settings/actions";
import { useAuth, useUserLevel } from "store/auth/selectors";
import { useConfig } from "store/settings/selectors";
import { getFirebaseServerTimestamp } from "config/Firebase";

const Routes = () => {
  const level = useUserLevel();
  const config = useConfig();
  const dispatch = useDispatch();
  const auth = useAuth();
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    if (auth.loggedIn) {
      dispatch(subscribeToConfig());
    }
  }, [dispatch, auth]);

  useEffect(() => {
    if (config) {
      getFirebaseServerTimestamp().then((timestamp) => {
        if (config.expiresAt - timestamp <= 0) {
          setExpired(true);
        }
      });
    }
  }, [config]);

  if (expired) {
    return (
      <MinimalLayout>
        <ServiceExpiredView />
      </MinimalLayout>
    );
  }

  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />

      <RouteWithLayout
        protection={level.isOwner}
        component={SlideListView}
        exact
        layout={MainLayout}
        path="/slides"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={SlideAddView}
        exact
        layout={MainLayout}
        path="/slides/add"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={SlideEditView}
        exact
        layout={MainLayout}
        path="/slides/:slideId/edit"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={DexListView}
        exact
        layout={MainLayout}
        path="/dexs"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={DexAddView}
        exact
        layout={MainLayout}
        path="/dexs/add"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={DexEditView}
        exact
        layout={MainLayout}
        path="/dexs/:dexId/edit"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={AdminListView}
        exact
        layout={MainLayout}
        path="/admins"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={AdminAddView}
        exact
        layout={MainLayout}
        path="/admins/add"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={AdminEditView}
        exact
        layout={MainLayout}
        path="/admins/:adminId/edit"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={ZoneListView}
        exact
        layout={MainLayout}
        path="/zones"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={ZoneAddView}
        exact
        layout={MainLayout}
        path="/zones/add"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={ZoneEditView}
        exact
        layout={MainLayout}
        path="/zones/:zoneId/edit"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={CouponListView}
        exact
        layout={MainLayout}
        path="/coupons"
      />
      <RouteWithLayout
        protection={level.isOwner}
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={StoreListView}
        exact
        layout={MainLayout}
        path="/stores"
      />
      <RouteWithLayout
        component={StoreAddView}
        exact
        layout={MainLayout}
        path="/stores/add"
      />
      <RouteWithLayout
        component={StoreEditView}
        exact
        layout={MainLayout}
        path="/stores/:storeId/edit"
      />
      <RouteWithLayout
        component={StoreCatalogView}
        exact
        layout={MainLayout}
        path="/stores/:storeId/catalog"
      />
      <RouteWithLayout
        component={CategoryAddView}
        exact
        layout={MainLayout}
        path="/stores/:storeId/categories/add"
      />
      <RouteWithLayout
        component={CategoryEditView}
        exact
        layout={MainLayout}
        path="/stores/:storeId/categories/:categoryId/edit"
      />
      <RouteWithLayout
        component={ItemAddView}
        exact
        layout={MainLayout}
        path="/stores/:storeId/items/add"
      />
      <RouteWithLayout
        component={ItemEditView}
        exact
        layout={MainLayout}
        path="/stores/:storeId/items/:itemId/edit"
      />

      <RouteWithLayout
        component={OrderListView}
        exact
        layout={MainLayout}
        path="/orders"
      />

      <RouteWithLayout
        component={CompletedOrderListView}
        exact
        layout={MainLayout}
        path="/orders/completed"
      />

      <RouteWithLayout
        component={OrderDetailsView}
        exact
        layout={MainLayout}
        path="/orders/:orderId"
      />

      <RouteWithLayout
        protection={level.isOwner}
        component={PushNotifications}
        exact
        layout={MainLayout}
        path="/push-notifications"
      />

      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />

      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />

      <RouteWithLayout
        component={Onboarding}
        exact
        layout={MinimalLayout}
        path="/onboarding"
      />

      <RouteWithLayout
        component={ServiceExpiredView}
        exact
        layout={MinimalLayout}
        path="/expired"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "store";
import { appActions } from "store/app/slice";
import axios from "axios";
import BusinessRequest from "typing/BusinessRequest";
import { baseUrl } from "store/user/actions";

export const onboard = createAsyncThunk(
  "onboard",
  async (
    { iconFile, data }: { iconFile: Blob; data: BusinessRequest },
    thunkApi
  ) => {
    try {
      let reader = new FileReader();

      reader.readAsDataURL(iconFile);

      const iconFileData = await new Promise((resolve) => {
        reader.onloadend = function () {
          resolve(reader.result);
        };
      });

      const state = thunkApi.getState() as AppState;

      const response = await axios.post(
        `${baseUrl}/onboard`,
        { ...data, iconFileData },
        {
          headers: {
            authorization: `Bearer ${state.app.onboardingUserToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const registerUserAndSendEmailVerification = createAsyncThunk(
  "registerUser",
  async (
    {
      email,
      password,
    }: {
      email: string;
      password: string;
    },
    thunkApi
  ) => {
    try {
      const response = await axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.REACT_APP_API_KEY}`,
        { email, password, returnSecureToken: true }
      );
      if (response.status === 200) {
        thunkApi.dispatch(
          appActions.setOnboardinguserToken(response.data.idToken)
        );
        thunkApi.dispatch(sendEmailVerification(response.data.idToken));
      }
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const sendEmailVerification = createAsyncThunk(
  "sendEmailVerification",
  async (idToken: string, thunkApi) => {
    try {
      const state = thunkApi.getState() as AppState;
      const response = await axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${process.env.REACT_APP_API_KEY}`,
        {
          requestType: "VERIFY_EMAIL",
          idToken: idToken || state.app.onboardingUserToken,
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, colors, List, ListItem, Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  title: {
    flex: 1,
    textAlign: "left",
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  subItem: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const [subMenu, setSubMenu] = useState({});

  const handleSubMenuChange = (key) => {
    setSubMenu({
      [key]: !Boolean(subMenu[key]),
    });
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => {
        return page.menu ? (
          <React.Fragment key={page.title}>
            <ListItem className={classes.item} disableGutters button>
              <Button
                className={classes.button}
                onClick={() => handleSubMenuChange(page.title)}
              >
                <div className={classes.icon}>{page.icon}</div>
                <div className={classes.title}>{page.title}</div>
                <div className={classes.icon}>
                  {subMenu[page.title] ? <ExpandLess /> : <ExpandMore />}
                </div>
              </Button>
            </ListItem>
            <Collapse in={subMenu[page.title]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {page.menu.map((item) => (
                  <ListItem
                    className={classes.subItem}
                    disableGutters
                    key={item.title}
                  >
                    <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={item.href}
                    >
                      <div className={classes.icon}>{item.icon}</div>
                      <div className={classes.title}>{item.title}</div>
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ) : (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
              <div className={classes.title}>{page.title}</div>
            </Button>
          </ListItem>
        );
      })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;

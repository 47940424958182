import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ServerTimestamp, makeRefs } from "config/Firebase";
import _ from "lodash";
import Category from "typing/Category";
import { addRefToTrash } from "utils/firebase";
import { getStateFromThunkApi } from "utils/utils";

export const subscribeToCategoryList = createAction(
  "subscribeToCategoryList",
  (storeId: string) => ({ payload: storeId })
);
export const unsubscribeToCategoryList = createAction(
  "unsubscribeToCategoryList"
);

export const addCategory = createAsyncThunk(
  "addCategory",
  async (category: Category, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    await refs.catalog
      .child(category.storeId)
      .child("category")
      .push({
        ..._.omit(category, ["storeId"]),
        updatedAt: ServerTimestamp,
      });
  }
);

export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (category: Category, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    await refs.catalog
      .child(category.storeId)
      .child("category")
      .child(category.id)
      .update({
        ..._.omit(category, ["id", "storeId"]),
        updatedAt: ServerTimestamp,
      });
  }
);

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async ({ id, storeId }: Pick<Category, "id" | "storeId">, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    const ref = refs.catalog.child(storeId).child("category").child(id);
    await ref.update({
      updatedAt: ServerTimestamp,
      deletedAt: ServerTimestamp,
    });
    await addRefToTrash(ref);
  }
);

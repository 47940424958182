import { takeLeading, put, takeEvery, take, select } from "redux-saga/effects";
import { subscribeToZoneList, unsubscribeToZoneList } from "./actions";
import { eventChannel } from "redux-saga";
import { selectRefs, Refs } from "config/Firebase";
import { zoneActions } from "./slice";
import { getListFromSnapshot } from "utils/firebase";
import { AppState } from "store";
import Zone from "typing/Zone";

export function* zoneListSubscriber() {
  yield takeLeading(subscribeToZoneList.type, listen);
}

function* listen() {
  const refs: Refs = yield selectRefs();
  const maxUpdatedAt = yield select(
    (state: AppState) => state.zone.maxUpdatedAt
  );

  const channel = eventChannel((emit) => {
    const ref = refs.zone.orderByChild("updatedAt").startAt(maxUpdatedAt + 1);

    ref.on("value", (snapshot) => {
      emit({ snapshot });
    });

    return () => {
      ref.off();
    };
  });

  yield takeEvery(channel, function* ({ snapshot }) {
    const list = getListFromSnapshot<Zone>(snapshot);
    yield put(zoneActions.patchList(list));
  });

  yield take(unsubscribeToZoneList.type);
  return channel.close();
}

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Coupon, { CouponUsageType } from "typing/Coupon";
import firebase from "firebase";
import { Firestore } from "config/Firebase";
import { getStateFromThunkApi } from "utils/utils";

export const subscribeToCouponList = createAction("subscribeToCouponList");
export const unsubscribeFromCouponList = createAction(
  "unsubscribeFromCouponList"
);

export const addCoupon = createAsyncThunk(
  "addCoupon",
  async (
    coupon: Omit<
      Coupon,
      "usageType" | "storeIds" | "customerIds" | "updatedAt"
    >,
    thunkApi
  ) => {
    const state = getStateFromThunkApi(thunkApi);
    const newCoupon: Coupon = {
      ...coupon,
      businessId: state.auth.user?.businessId!,
      usageType: CouponUsageType.OPEN,
      storeIds: [],
      customerIds: [],
      updatedAt: firebase.firestore.Timestamp.now().toMillis(),
    };
    await Firestore.collection("coupon").doc(coupon.code).set(newCoupon);
  }
);

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ServerTimestamp, makeRefs } from "config/Firebase";
import Slide from "typing/Slide";
import { deleteFile } from "store/actions";
import { addRefToTrash } from "utils/firebase";
import { getStateFromThunkApi } from "utils/utils";
import _ from "lodash";

export const subscribeToSlideList = createAction("subscribeToSlideList");
export const unsubscribeToSlideList = createAction("unsubscribeToSlideList");

export const addSlide = createAsyncThunk(
  "addSlide",
  async (slide: Slide, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));

    await refs.slide.push({
      ...slide,
      updatedAt: ServerTimestamp,
    });
  }
);

export const updateSlide = createAsyncThunk(
  "updateSlide",
  async (slide: Slide, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    const id = slide.id;
    await refs.slide.child(id).update({
      ..._.omit(slide, ["id"]),
      updatedAt: ServerTimestamp,
    });
  }
);

export const deleteSlide = createAsyncThunk(
  "deleteSlide",
  async (slide: Slide, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    const ref = refs.slide.child(slide.id);
    thunkApi.dispatch(deleteFile(slide.imageUrl));

    await ref.update({
      updatedAt: ServerTimestamp,
      deletedAt: ServerTimestamp,
    });
    await addRefToTrash(ref);
  }
);

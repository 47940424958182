import React, { ComponentProps } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const WithLoading: React.FC<
  ComponentProps<typeof CircularProgress> & { loading: boolean }
> = ({ loading, children, ...restProps }) => {
  return loading ? (
    <Box mt={4} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress {...restProps} />
    </Box>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

export default WithLoading;

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import User from "typing/User";

export const subscribeToExeList = createAction("subscribeToExeList");
export const unsubscribeFromExeList = createAction("unsubscribeToExeList");
export const subscribeToAdminList = createAction("subscribeToAdminList");
export const unsubscribeFromAdminList = createAction("unsubscribeToAdminList");

export const baseUrl = process.env.REACT_APP_API_URL;

export const addUser = createAsyncThunk(
  "addUser",
  async (
    user: Pick<User, "name" | "email" | "phone" | "level"> & {
      password: string;
    },
    thunkApi
  ) => {
    try {
      const response = await axios.post(`${baseUrl}/createUser`, user, {
        headers: getAuthHeaders(thunkApi),
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async (
    user: Pick<User, "id" | "name" | "email" | "phone"> & { password?: string },
    thunkApi
  ) => {
    try {
      const response = await axios.post(`${baseUrl}/updateUser`, user, {
        headers: getAuthHeaders(thunkApi),
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (user: Pick<User, "id">, thunkApi) => {
    try {
      const response = await axios.post(`${baseUrl}/deleteUser`, user, {
        headers: getAuthHeaders(thunkApi),
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

const getAuthHeaders = (api: any) => {
  const accessToken = getAccessToken(api);
  return { authorization: `Bearer ${accessToken}` };
};

const getAccessToken = (api: any): string =>
  api.getState().auth.user?.accessToken;

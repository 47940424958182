import React, { ReactElement, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { EMAIL_REGEX, themes } from "appContants";
import { ImagePicker } from "components";
import Dialog, { useDialog } from "components/Dialog/Dialog";

import { useThunkDispatch } from "store";
import {
  onboard,
  registerUserAndSendEmailVerification,
  sendEmailVerification,
} from "store/app/thunks";
import BusinessRequest from "typing/BusinessRequest";
import { Auth } from "config/Firebase";

interface FormStep {
  title: string;
  subtitle: string;
  view: () => ReactElement<any, any>;
  validate: () => Promise<boolean>;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.black,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.black,
  },
  bio: {
    color: theme.palette.black,
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  themeHint: {
    display: "inline",
    border: "1px black solid",
    borderRadius: 100,
    margin: theme.spacing(1),
    width: 30,
    height: 30,
  },
}));

const Onboarding: React.FC = () => {
  const classes = useStyles();
  const thunkDispatch = useThunkDispatch();

  const { register, trigger, errors, watch, getValues, control } = useForm({
    defaultValues: {} as BusinessRequest & { theme: string },
  });

  const dialog = useDialog();

  const hasError = (fieldName: string) => !!_.get(errors, fieldName);
  const getError = (fieldName: string) => _.get(errors, `${fieldName}.message`);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [icon, setIcon] = useState<Blob | null>(null);

  const steps: FormStep[] = [
    {
      title: "Welcome",
      subtitle: "Start you online business in few mintues",
      view: () => {
        return (
          <div>Just few steps away from starting your online business.</div>
        );
      },
      validate: async () => true,
    },

    {
      title: "Business details",
      subtitle: "Provide your business name and logo",
      view: () => {
        return (
          <div>
            <Box height={4} />
            <ImagePicker
              className={classes.textField}
              onSelect={(file) => {
                if (file.size > 1024 * 100) {
                  dialog.show({
                    title: "Image Size",
                    message: "Image size should be less then 100KB",
                  });
                  return false;
                }
                setIcon(file);
                return true;
              }}
            />
            <Box height={4} />
            <TextField
              inputRef={register({ required: "Please enter business name" })}
              className={classes.textField}
              fullWidth
              label="Business Name"
              name="business.name"
              type="text"
              variant="outlined"
              error={hasError("business.name")}
              helperText={getError("business.name")}
            />

            <Box height={8} />
            <FormControl variant="outlined" fullWidth error={hasError("theme")}>
              <InputLabel>Theme Color</InputLabel>
              <Controller
                name="theme"
                control={control}
                rules={{ required: "Please select business theme" }}
                as={
                  <Select>
                    {themes.map((value) => (
                      <MenuItem
                        value={value.data.primaryColor}
                        key={value.data.primaryColor}
                      >
                        <span
                          className={classes.themeHint}
                          style={{ backgroundColor: value.data.primaryColor }}
                        />
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
              <FormHelperText>{getError("theme")}</FormHelperText>
            </FormControl>

            <TextField
              inputRef={register({
                required: "Please enter your business description",
              })}
              className={classes.textField}
              fullWidth
              label="Business description"
              placeholder="Let us little bit about your business..."
              name="business.description"
              multiline
              rows={3}
              type="text"
              variant="outlined"
              error={hasError("business.description")}
              helperText={getError("business.description")}
            />
          </div>
        );
      },
      validate: async () => {
        if (!icon) {
          dialog.show({
            title: "Business Logo",
            message: "Please upload business logo",
          });

          return false;
        }

        return trigger(["business.name", "business.description", "theme"]);
      },
    },
    {
      title: "User details",
      subtitle: "Enter user details",
      view: () => {
        return (
          <div>
            <TextField
              inputRef={register({ required: "Please enter name" })}
              className={classes.textField}
              fullWidth
              label="Name"
              name="user.name"
              type="text"
              variant="outlined"
              error={hasError("user.name")}
              helperText={getError("user.name")}
            />
            <TextField
              inputRef={register({
                required: "Please enter email",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Please enter valid email address",
                },
              })}
              className={classes.textField}
              fullWidth
              label="Email"
              name="user.email"
              type="text"
              variant="outlined"
              error={hasError("user.email")}
              helperText={getError("user.email")}
            />
            <TextField
              inputRef={register({
                required: "Please enter phone number",
                validate: (value) =>
                  (Number(value) && value.length === 10) ||
                  "Please enter valid phone number",
              })}
              className={classes.textField}
              fullWidth
              label="Phone Number"
              name="user.phone"
              type="text"
              variant="outlined"
              error={hasError("user.phone")}
              helperText={getError("user.phone")}
            />
            <TextField
              inputRef={register({
                required: "Please enter password",
                minLength: {
                  value: 6,
                  message: "Password should be atleast 6 characters",
                },
              })}
              className={classes.textField}
              fullWidth
              label="Password"
              name="user.password"
              type="password"
              variant="outlined"
              error={hasError("user.password")}
              helperText={getError("user.password")}
            />
            <TextField
              inputRef={register({
                validate: (value) =>
                  watch("user.password") === value ||
                  "Confirm password should be same as password",
              })}
              className={classes.textField}
              fullWidth
              label="Confirm Password"
              name="user.confirmPassword"
              type="password"
              variant="outlined"
              error={hasError("user.confirmPassword")}
              helperText={getError("user.confirmPassword")}
            />
          </div>
        );
      },
      validate: () =>
        trigger([
          "user.name",
          "user.email",
          "user.phone",
          "user.password",
          "user.confirmPassword",
        ]),
    },

    {
      title: "Almost there …",
      subtitle: `Please check your email (${watch(
        "user.email"
      )}) to confirm your account.`,
      view: () => {
        return (
          <Typography>
            Didn't receive the email?{" "}
            <Button
              variant="text"
              onClick={async () => {
                try {
                  setLoading(true);
                  await thunkDispatch(sendEmailVerification(""));
                } finally {
                  dialog.show({
                    title: "Email sent",
                    message: "Verification email is sent.",
                  });
                  setLoading(false);
                }
              }}
            >
              Resend
            </Button>
          </Typography>
        );
      },
      validate: async () => true,
    },
  ];

  const currentStep = steps[currentStepIndex];

  const handleNext = async () => {
    if (!(await currentStep.validate())) {
      return;
    }

    const values = getValues();

    if (currentStepIndex === steps.length - 2) {
      try {
        setLoading(true);
        const action = await thunkDispatch(
          registerUserAndSendEmailVerification({
            email: values.user?.email!,
            password: values.user?.password!,
          })
        );

        if (action.payload.error) {
          throw action.payload.error;
        }
      } catch {
        // TODO: show proper status messages
        dialog.show({
          title: "Oops...",
          message: "Something went wrong, please try again.",
        });
        return;
      } finally {
        setLoading(false);
      }
    } else if (currentStepIndex === steps.length - 1) {
      try {
        setLoading(true);

        const action = await thunkDispatch(
          onboard({
            iconFile: icon!,
            data: {
              ...values,
              business: {
                ...values.business,
                theme: themes.find(
                  (theme) => values.theme === theme.data.primaryColor
                )?.data!,
              },
            },
          })
        );

        if (action.payload.error) {
          throw action.payload.error;
        }

        await Auth.signInWithCustomToken(action.payload.authToken);
      } catch (error) {
        // TODO: show proper status messages
        dialog.show({
          title: "Oops...",
          message: "Something went wrong, please try again.",
        });
      } finally {
        setLoading(false);
      }
    }

    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  const handlePrevious = async () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Simplifying your business
              </Typography>
              <div>
                <Typography className={classes.name} variant="body1">
                  Expressify
                </Typography>
                <Typography className={classes.bio} variant="body2">
                  A product of Autosoft
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <Typography className={classes.title} variant="h2">
                  {currentStep.title}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  {currentStep.subtitle}
                </Typography>
                {steps.map((step, index) => (
                  <div
                    key={`step-${index}`}
                    style={{
                      display: index === currentStepIndex ? "block" : "none",
                    }}
                  >
                    {step.view()}
                  </div>
                ))}

                <Box display="flex" justifyContent="space-between">
                  {currentStepIndex > 0 && (
                    <Button
                      className={classes.signInButton}
                      size="medium"
                      variant="contained"
                      onClick={handlePrevious}
                      startIcon={<ChevronLeftIcon />}
                    >
                      Previous
                    </Button>
                  )}
                  <Button
                    fullWidth={currentStepIndex === 0}
                    className={classes.signInButton}
                    color="primary"
                    size="medium"
                    variant="contained"
                    onClick={handleNext}
                    endIcon={<ChevronRightIcon />}
                    disabled={loading}
                  >
                    {currentStepIndex === 0 && "Get started"}
                    {currentStepIndex === steps.length - 1 && "Finish"}
                    {currentStepIndex > 0 &&
                      currentStepIndex < steps.length - 1 &&
                      "Next"}
                  </Button>
                </Box>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog {...dialog.props} />
    </div>
  );
};

export default Onboarding;

import React, { useCallback, ComponentProps } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

const BackButton: React.FC<ComponentProps<typeof Button>> = ({
  children,
  ...restProps
}) => {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Button variant="contained" {...restProps} onClick={handleGoBack}>
      {children}
    </Button>
  );
};

export default BackButton;

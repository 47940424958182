import React from "react";
import { useParams, Redirect } from "react-router-dom";
import {
  Container,
  CardHeader,
  Card,
  CardContent,
  Typography,
  Divider,
  CardActions,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BackButton } from "components";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useOrderItem } from "store/order/selectors";
import moment from "moment";
import OrderStatusBullet from "../OrderList/components/OrderStatusBullet";

const useStyles = makeStyles((theme: any) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  statsItem: {
    display: "flex",
    alignItems: "center",
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}));

const OrderDetails = () => {
  const classes = useStyles();

  const { orderId } = useParams<{ orderId: string }>();

  const order = useOrderItem(orderId);

  if (!order) {
    return <Redirect to="/orders" />;
  }

  return (
    <Container maxWidth="md" className={classes.root}>
      <Card>
        <CardHeader
          title={`Order #${orderId}`}
          action={
            <BackButton size="small" variant="outlined">
              Back
            </BackButton>
          }
        />
        <CardContent>
          <Typography align="center" gutterBottom variant="h4">
            {order?.store.name}
          </Typography>
          <Typography align="center" variant="body1">
            by {order?.customer.name}
          </Typography>
          <Box height={16} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map((it) => (
                  <TableRow key={it.itemId}>
                    <TableCell>{it.itemName}</TableCell>
                    <TableCell align="right">{it.itemQuantity}</TableCell>
                    <TableCell align="right">
                      {it.itemQuantity * it.itemPrice}
                    </TableCell>
                  </TableRow>
                ))}
                <Divider />
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">{order.itemsTotal}</TableCell>
                </TableRow>
                <Divider />
                {order.couponCode && (
                  <React.Fragment>
                    <TableRow>
                      <TableCell>Coupon ({order.offer.coupon})</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {order.offer.discount}
                      </TableCell>
                    </TableRow>
                    <Divider />
                  </React.Fragment>
                )}
                {order.charges.map((it) => (
                  <TableRow key={it.chargeLabel}>
                    <TableCell>{it.chargeLabel}</TableCell>
                    <TableCell align="right" />
                    <TableCell align="right">{it.chargeAmount}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>Bill Amount</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">{order.billAmount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid container justify="space-between">
            <Grid className={classes.statsItem} item>
              <AccessTimeIcon className={classes.statsIcon} />
              <Typography display="inline" variant="body2">
                {moment().diff(moment(order?.time), "hour")} hours ago
              </Typography>
            </Grid>
            <Grid className={classes.statsItem} item>
              <OrderStatusBullet status={order.status} />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  );
};

export default OrderDetails;

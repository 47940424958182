import { takeLeading, put, takeEvery, take, select } from "redux-saga/effects";
import {
  subscribeToExeList,
  unsubscribeFromExeList,
  subscribeToAdminList,
  unsubscribeFromAdminList,
} from "./actions";
import { eventChannel } from "redux-saga";
import { selectRefs, Refs } from "config/Firebase";
import { userActions } from "./slice";
import { getListFromSnapshot } from "utils/firebase";
import { AppState } from "store";
import User, { UserLevel } from "typing/User";

export function* exeListSubscriber() {
  yield takeLeading(subscribeToExeList.type, exeListListen);
}

function* exeListListen() {
  const refs: Refs = yield selectRefs();
  const user = yield select((state: AppState) => state.auth.user);
  const zoneId = user.zoneId;

  const channel = eventChannel((emit) => {
    const ref = refs.user
      .orderByChild("zoneId_level")
      .startAt(`${zoneId}_${UserLevel.OEX}`);

    ref.on("value", (snapshot) => {
      emit({ snapshot });
    });

    return () => {
      ref.off();
    };
  });

  yield takeEvery(channel, function* ({ snapshot }) {
    const list = getListFromSnapshot<User>(snapshot);
    yield put(userActions.patchExeList(list));
  });

  yield take(unsubscribeFromExeList.type);
  return channel.close();
}

export function* adminListSubscriber() {
  yield takeLeading(subscribeToAdminList.type, adminListListen);
}

function* adminListListen() {
  const refs: Refs = yield selectRefs();
  const channel = eventChannel((emit) => {
    const ref = refs.user.orderByChild("level").equalTo(UserLevel.ADMIN);

    ref.on("value", (snapshot) => {
      emit({ snapshot });
    });

    return () => {
      ref.off();
    };
  });

  yield takeEvery(channel, function* ({ snapshot }) {
    const list = getListFromSnapshot<User>(snapshot);
    yield put(userActions.patchAdminList(list));
  });

  yield take(unsubscribeFromAdminList.type);
  return channel.close();
}

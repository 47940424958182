import { createAsyncThunk } from "@reduxjs/toolkit";
import Order, { Status } from "typing/Order";
import { Firestore } from "config/Firebase";
import User from "typing/User";

export const updateOrderStatus = createAsyncThunk(
  "updateOrderStatus",
  async ({ orderId, status }: Pick<Order, "orderId" | "status">) => {
    let updates: any = { status };

    if (status === Status.CANCELLED || status === Status.DELIVERED) {
      updates = {
        ...updates,
        state: 0,
      };
    }

    Firestore.collection("order").doc(orderId).update(updates);
  }
);

export const updateOrderToConfirmed = createAsyncThunk(
  "updateOrderToPickedUp",
  async ({ orderId, dex }: { orderId: string; dex: User }) => {
    Firestore.collection("order")
      .doc(orderId)
      .update({
        status: Status.CONFIRMED,
        dex: {
          name: dex.name,
          id: dex.id,
        },
      });
  }
);

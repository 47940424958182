import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import { useStats } from "store/stats/selectors";
import { MONTHLY_FORMAT } from "appContants";
import moment from "moment";
import { WithLoading } from "components";
import { calculateChange } from "utils/utils";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 136,
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  improvedColor: {
    color: theme.palette.success.dark,
  },
  worsenColor: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
}));

const TotalUsers = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const stats = useStats();

  const thisMonth = stats.totalUserCountStats[moment().format(MONTHLY_FORMAT)];

  const lastMonth =
    stats.totalUserCountStats[
      moment().subtract(1, "months").format(MONTHLY_FORMAT)
    ];

  const { isPostive, absDiffPercent } = calculateChange(lastMonth, thisMonth);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <WithLoading loading={stats.totalUserCountLoading}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                TOTAL USERS
              </Typography>
              <Typography variant="h3">{thisMonth}</Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <PeopleIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
          <div className={classes.difference}>
            {isPostive ? (
              <ArrowUpwardIcon className={classes.improvedColor} />
            ) : (
              <ArrowDownwardIcon className={classes.worsenColor} />
            )}
            <Typography
              className={clsx(
                {
                  [classes.improvedColor]: isPostive,
                  [classes.worsenColor]: !isPostive,
                },
                classes.differenceValue
              )}
              variant="body2"
            >
              {absDiffPercent}%
            </Typography>
            <Typography className={classes.caption} variant="caption">
              Since last month
            </Typography>
          </div>
        </CardContent>
      </WithLoading>
    </Card>
  );
};

TotalUsers.propTypes = {
  className: PropTypes.string,
};

export default TotalUsers;

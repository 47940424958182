export enum UserLevel {
  OWNER = 1,
  ADMIN = 2,
  OEX = 3,
  DEX = 4,
}

export default interface User {
  id: string;
  name: string;
  phone: string;
  email: string;
  level: UserLevel;
  zoneId: string;
  businessId: string;
  accessToken?: string;
  updatedAt?: string;
}

import { useSelector } from "react-redux";
import { AppState } from "store";
import { useMemo } from "react";
import { filterArrayBySearch } from "utils/utils";

export const useZoneList = () => useSelector((state: AppState) => state.zone);

export const useZoneItem = (id: string) => {
  const data = useZoneList();
  return useMemo(() => data.list.find((it) => it.id === id), [data, id]);
};

export const useFilteredZone = () => {
  const data = useZoneList();
  return useMemo(
    () => filterArrayBySearch(data.list, data.searchText, "name"),
    [data.list, data.searchText]
  );
};

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { CategoryListTable, CategoryListToolbar } from "./components";
import { Theme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useCategoryList, useFilteredCategory } from "store/category/selectors";
import { useParams } from "react-router-dom";
import {
  subscribeToCategoryList,
  unsubscribeToCategoryList,
} from "store/category/actions";
import { WithLoading } from "components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const CategoryList = () => {
  const classes = useStyles();
  const { storeId } = useParams<{ storeId: string }>();
  const categoryList = useCategoryList(storeId);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToCategoryList(storeId));

    return () => {
      dispatch(unsubscribeToCategoryList());
    };
  }, [dispatch, storeId]);

  const categoryListData = useFilteredCategory(storeId);

  return (
    <div className={classes.root}>
      <WithLoading loading={categoryList.loading}>
        <CategoryListToolbar storeId={storeId} />
        <div className={classes.content}>
          <CategoryListTable
            storeId={storeId}
            categoryList={categoryListData}
          />
        </div>
      </WithLoading>
    </div>
  );
};

export default CategoryList;

export enum CouponType {
  FREE_DELIVERY = "FREE_DELIVERY",
  DISCOUNT = "DISCOUNT",
}

export enum CouponUsageType {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}

export const COUPON_AVAIL_TIME_FORMAT = "HH:mm";

export default interface Coupon {
  businessId: string;
  name: string;
  code: string;
  newUsersOnly: boolean;
  validFrom: string;
  expiresAt: string;
  type: CouponType;
  usageType: CouponUsageType;
  usageLimit: number;
  discountPercent: number;
  maxDiscount: number;
  minOrderValue: number;
  availStartTime: string;
  availEndTime: string;
  storeIds: string[];
  customerIds: string[];
  updatedAt: any;
}

export interface CouponUsageEntries {
  [id: string]: number;
}

export interface CouponUsage {
  restaurants: CouponUsageEntries;
  customers: CouponUsageEntries;
}

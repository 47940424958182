import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme } from "@material-ui/core";

import {
  TotalOrders,
  TotalUsers,
  CancelledOrders,
  TotalEarnings,
  OrdersOverview,
  OrdersRatio,
} from "./components";
import { useDispatch } from "react-redux";
import { subscribeToStats } from "store/stats/actions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToStats());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalOrders />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <CancelledOrders />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalEarnings />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <OrdersOverview />
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <OrdersRatio />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;

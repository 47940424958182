export enum ItemFeature {
  NONE = 0,
  BEST_SELLER = 1,
  ONLY_HERE = 2,
  TOP_PICK = 3,
}

export default interface Item {
  id: string;
  storeId: string;
  name: string;
  featured: ItemFeature;
  categoryId: string;
  imageUrl: string;
  inStock: boolean;
  price: number;
  tags: string;
  veg: boolean;
  updatedAt: number;
  deletedAt?: number;
}

import React, { useState, useCallback } from "react";
import StoreForm from "../StoreForm/StoreForm";
import { useThunkDispatch } from "store";
import { uploadFile, deleteFile } from "store/actions";
import { updateStore } from "store/store/actions";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useStoreItem } from "store/store/selectors";

const StoreEdit: React.FC = () => {
  const thunkDispatch = useThunkDispatch();
  const [image, setImage] = useState<Blob | null>(null);
  const history = useHistory();
  const params = useParams<{ storeId: string }>();

  const store = useStoreItem(params.storeId);

  const handleImageSelect = useCallback((file) => {
    if (file.size > 1024 * 1024 * 15) {
      return "Image size should be less then 15kb";
    }
    setImage(file);
    return null;
  }, []);

  const handleFinish = useCallback(
    async (values) => {
      try {
        let imageUri = null;
        if (image) {
          const imageUpload = await thunkDispatch(
            uploadFile({ path: "images/store", file: image })
          );
          setImage(null);
          imageUri = imageUpload.payload;
          if (store?.imageUrl) {
            thunkDispatch(deleteFile(store.imageUrl));
          }
        }
        await thunkDispatch(
          updateStore({
            id: store?.id,
            ...values,
            ...(imageUri && { imageUrl: imageUri }),
          })
        );
        history.goBack();
      } catch (error) {
        console.log(error);
        // TODO: handle
      }
    },
    [image, thunkDispatch, history, store]
  );

  if (!store) {
    return <Redirect to="/stores" />;
  }

  return (
    <StoreForm
      title="Edit Store"
      subtitle={`Editing ${store?.name}`}
      onImageSelect={handleImageSelect}
      onFinish={handleFinish}
      defaultValues={store}
      goToUserLocation={false}
      okText="Save"
    />
  );
};

export default StoreEdit;

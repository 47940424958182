export enum StoreStatus {
  OPEN = 1,
  CLOSED = 0,
  UNSERVICEABLE = -1,
}

export default interface Store {
  id: string;
  name: string;
  location: string;
  featured: number;
  imageUrl: string;
  desc: string;
  phone: string;
  openTime: string;
  closeTime: string;
  status: StoreStatus;
  l: [number, number];
  g: string;
  updatedAt: number;
  deletedAt?: number;
}

import React, { useCallback, useState } from "react";
import UserForm from "../UserForm/UserForm";
import { useThunkDispatch } from "store";
import { useHistory } from "react-router-dom";
import { addUser } from "store/user/actions";
import { UserLevel } from "typing/User";

const DexAdd: React.FC = () => {
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();
  const [error, setError] = useState<string | undefined>(undefined);
  const handleErrorClose = useCallback(() => {
    setError(undefined);
  }, []);

  const handleFinish = useCallback(
    async (values) => {
      const action = await thunkDispatch(
        addUser({ level: UserLevel.DEX, ...values })
      );

      if (action.type === addUser.rejected.toString()) {
        setError(action.payload);
      } else {
        history.goBack();
      }
    },
    [thunkDispatch, history]
  );

  return (
    <UserForm
      title="Add delivery executive"
      subtitle="Creating new delivery executive"
      onFinish={handleFinish}
      okText="Add"
      editing={false}
      error={error}
      onErrorClose={handleErrorClose}
    />
  );
};

export default DexAdd;

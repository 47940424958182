import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Theme,
  Container,
  Box,
} from "@material-ui/core";
import { BackButton, ImagePicker, Dialog } from "components";
import _ from "lodash";
import { useDialog } from "components/Dialog/Dialog";
import Slide from "typing/Slide";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

interface SlideFormProps {
  title: string;
  subtitle: string;
  defaultValues?: Slide;
  onFinish: (values: Slide) => Promise<void>;
  onImageSelect: (file: Blob) => string | null;
  okText: string;
}

const SlideForm: React.FC<SlideFormProps> = ({
  title,
  subtitle,
  defaultValues,
  onFinish,
  onImageSelect,
  okText,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const dialog = useDialog();

  const { register, errors, trigger, getValues } = useForm({
    defaultValues,
  });

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);
      if (await trigger()) {
        const values = getValues() as any;
        await onFinish({
          ...values,
          position: Number(values.position),
        });
      }

      if (errors["imageUrl"]) {
        dialog.show({
          title: "Slide Image",
          message: "Please select slide image",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [trigger, getValues, onFinish, errors, dialog]);

  const handleImageSelect = useCallback(
    (image) => {
      const result = onImageSelect(image);
      if (result !== null) {
        dialog.show({ title: "Error", message: result });
      }
      return result === null;
    },
    [dialog, onImageSelect]
  );

  const hasError = (fieldName: string) => !!_.get(errors, fieldName);

  return (
    <Container maxWidth="md" className={classes.root}>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader title={title} subheader={subtitle} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid
                item
                md={6}
                xs={12}
                container
                alignItems="center"
                justify="center"
              >
                <ImagePicker
                  defaultImage={_.get(defaultValues, "imageUrl")}
                  layout="box"
                  onSelect={handleImageSelect}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the store title"
                  label="Slide title"
                  margin="dense"
                  name="title"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={hasError("title")}
                />

                <Box height={8} />

                <TextField
                  fullWidth
                  label="Select Position"
                  margin="dense"
                  name="position"
                  error={hasError("position")}
                  inputRef={register({ required: true })}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  {_.range(1, 10).map((position) => (
                    <option key={`position-${position}`} value={position}>
                      {position}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleFinish}
              disabled={loading}
            >
              {okText}
            </Button>
            <BackButton>Cancel</BackButton>
          </CardActions>
        </form>
      </Card>
      <Dialog {...dialog.props} />
    </Container>
  );
};

export default SlideForm;

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Theme,
  Button,
  Avatar,
  Typography,
} from "@material-ui/core";
import { Dialog } from "components";
import usePagination from "hooks/Pagination";
import { Link } from "react-router-dom";
import User from "typing/User";
import { getInitials } from "helpers";
import { useDialog } from "components/Dialog/Dialog";
import { deleteUser } from "store/user/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  image: {
    objectFit: "cover",
    width: 120,
    height: 50,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

export interface UserListTableProps {
  makeEditUrl: (user: User) => string;
  list: Array<User>;
}

const UserListTable: React.FC<UserListTableProps> = ({ list, makeEditUrl }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    startAt,
    endAt,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
  } = usePagination();

  const [targetSlide, setTargetSlide] = useState<User | null>(null);

  const dialog = useDialog(
    useCallback(
      (positive) => {
        if (positive) {
          dispatch(deleteUser(targetSlide!));
        }
      },
      [dispatch, targetSlide]
    )
  );

  const handleDelete = useCallback(
    (user) => () => {
      setTargetSlide(user);
      dialog.show({
        title: "Are you sure?",
        message: "You want to delete this user",
      });
    },
    [dialog]
  );

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(startAt, endAt).map((it) => (
                  <TableRow hover key={it.id}>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar className={classes.avatar}>
                          {getInitials(it.name)}
                        </Avatar>
                        <Typography variant="body1">{it.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{it.email}</TableCell>
                    <TableCell>{it.phone}</TableCell>
                    <TableCell align="center">
                      <Button
                        size="small"
                        component={Link}
                        to={makeEditUrl(it)}
                      >
                        Edit
                      </Button>
                      <Button size="small" onClick={handleDelete(it)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={list.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <Dialog {...dialog.props} showNegativeButton={true} />
    </Card>
  );
};

UserListTable.propTypes = {
  list: PropTypes.array.isRequired,
};

export default UserListTable;

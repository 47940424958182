import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Theme,
  Container,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { BackButton, Dialog, Alert } from "components";
import _ from "lodash";
import { useDialog } from "components/Dialog/Dialog";
import User from "typing/User";
import { useZoneList } from "store/zone/selectors";
import { useDispatch } from "react-redux";
import { subscribeToZoneList, unsubscribeToZoneList } from "store/zone/actions";
import { useUserLevel } from "store/auth/selectors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  title: string;
  subtitle: string;
  defaultValues?: User;
  onFinish: (values: User) => Promise<void>;
  okText: string;
  editing: boolean;
  error?: string;
  onErrorClose?: () => void;
}

const UserForm: React.FC<Props> = ({
  title,
  subtitle,
  defaultValues,
  onFinish,
  okText,
  editing,
  error,
  onErrorClose,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const dialog = useDialog();

  const level = useUserLevel();

  const { register, errors, trigger, getValues } = useForm({
    defaultValues,
  });

  const handleFinish = useCallback(async () => {
    try {
      onErrorClose && onErrorClose();
      setLoading(true);
      if (await trigger()) {
        const values = getValues() as any;
        await onFinish({
          ...values,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [trigger, getValues, onFinish, onErrorClose]);

  const hasError = (fieldName: string) => !!_.get(errors, fieldName);

  const zoneList = useZoneList();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToZoneList());
    return () => {
      dispatch(unsubscribeToZoneList());
    };
  }, [dispatch]);

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader title={title} subheader={subtitle} />
          <Divider />
          <CardContent>
            {error && (
              <Box mb={2}>
                <Alert severity="error" onClose={onErrorClose}>
                  {error}
                </Alert>
              </Box>
            )}
            <TextField
              fullWidth
              label="Name"
              margin="dense"
              name="name"
              variant="outlined"
              inputRef={register({ required: true })}
              error={hasError("name")}
            />

            <TextField
              fullWidth
              label="Email Address"
              margin="dense"
              name="email"
              variant="outlined"
              inputRef={register({ required: true })}
              error={hasError("email")}
            />

            <TextField
              fullWidth
              label="Phone Number"
              placeholder="+91-1234567890"
              margin="dense"
              name="phone"
              variant="outlined"
              inputRef={register({ required: true })}
              error={hasError("phone")}
            />

            <TextField
              fullWidth
              label={editing ? "Update Password (Optional)" : "Password"}
              margin="dense"
              name="password"
              variant="outlined"
              inputRef={register({ required: !editing })}
              error={hasError("password")}
            />

            {level.isOwner && (
              <FormControl variant="outlined">
                <InputLabel>Zone</InputLabel>
                <Select
                  inputRef={register}
                  name="zoneId"
                  defaultValue={defaultValues?.zoneId}
                  className={classes.marginTop}
                  fullWidth
                  error={hasError("zoneId")}
                  variant="outlined"
                >
                  {zoneList.list.map((zone) => (
                    <MenuItem value={zone.id} key={zone.id}>
                      {zone.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleFinish}
              disabled={loading}
            >
              {okText}
            </Button>
            <BackButton>Cancel</BackButton>
          </CardActions>
        </form>
      </Card>
      <Dialog {...dialog.props} />
    </Container>
  );
};

export default UserForm;

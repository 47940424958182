import React, { useCallback } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Theme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
} from "@material-ui/core";

import { getInitials } from "helpers";
import Store, { StoreStatus } from "typing/Store";
import { setStoreListStatus, deleteStore } from "store/store/actions";
import { useDispatch } from "react-redux";
import usePagination from "hooks/Pagination";
import { useHistory } from "react-router-dom";
import { PopupMenu } from "components";
import { appActions } from "store/app/slice";
import { useSelectedStoreIds } from "store/app/selectors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  formControl: {
    minWidth: 120,
  },
}));

const StoreListTable: React.FC<{ storeList: Array<Store> }> = ({
  storeList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    startAt,
    endAt,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
  } = usePagination();

  const selectedStoreIds = useSelectedStoreIds();

  const handleStatusChange = useCallback(
    (store) => (event: any) => {
      dispatch(
        setStoreListStatus([
          { id: store.id, status: Number(event.target.value) },
        ])
      );
    },
    [dispatch]
  );

  const handleMore = useCallback(
    (store: Store) => (option: string) => {
      if (option === "Manage Catalog") {
        history.push(`/stores/${store.id}/catalog`);
      } else if (option === "Edit") {
        history.push(`/stores/${store.id}/edit`);
      } else if (option === "Delete") {
        dispatch(deleteStore(store));
      }
    },
    [history, dispatch]
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelectedStoreIds: string[] = [];

    if (event.target.checked) {
      newSelectedStoreIds = storeList.map((store) => store.id);
    } else {
      newSelectedStoreIds = [];
    }

    dispatch(appActions.setSelectedStoreIds(newSelectedStoreIds));
  };

  const handleSelectOne = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const selectedIndex = selectedStoreIds.indexOf(id);
    let newSelectedStoreIds: string[] = [];

    if (selectedIndex === -1) {
      newSelectedStoreIds = newSelectedStoreIds.concat(selectedStoreIds, id);
    } else if (selectedIndex === 0) {
      newSelectedStoreIds = newSelectedStoreIds.concat(
        selectedStoreIds.slice(1)
      );
    } else if (selectedIndex === selectedStoreIds.length - 1) {
      newSelectedStoreIds = newSelectedStoreIds.concat(
        selectedStoreIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedStoreIds = newSelectedStoreIds.concat(
        selectedStoreIds.slice(0, selectedIndex),
        selectedStoreIds.slice(selectedIndex + 1)
      );
    }

    dispatch(appActions.setSelectedStoreIds(newSelectedStoreIds));
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedStoreIds.length === storeList.length}
                      color="primary"
                      indeterminate={
                        selectedStoreIds.length > 0 &&
                        selectedStoreIds.length < storeList.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>More</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storeList.slice(startAt, endAt).map((store) => (
                  <TableRow hover key={store.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedStoreIds.indexOf(store.id) !== -1}
                        onChange={(event) => handleSelectOne(event, store.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar className={classes.avatar} src={store.imageUrl}>
                          {getInitials(store.name)}
                        </Avatar>
                        <Typography variant="body1">{store.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{store.desc}</TableCell>
                    <TableCell>{store.location}</TableCell>
                    <TableCell>
                      <FormControl className={classes.formControl}>
                        <InputLabel id={`store-${store.id}-status`}>
                          Change status
                        </InputLabel>
                        <Select
                          labelId={`store-${store.id}-status`}
                          value={store.status}
                          onChange={handleStatusChange(store)}
                        >
                          <MenuItem value={StoreStatus.OPEN}>Open</MenuItem>
                          <MenuItem value={StoreStatus.CLOSED}>Closed</MenuItem>
                          <MenuItem value={StoreStatus.UNSERVICEABLE}>
                            Unserviceable
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <PopupMenu
                        options={["Manage Catalog", "Edit", "Delete"]}
                        onSelect={handleMore(store)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={storeList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

StoreListTable.propTypes = {
  storeList: PropTypes.array.isRequired,
};

export default StoreListTable;

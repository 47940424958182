import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  TextField,
  Divider,
  Theme,
  FormControlLabel,
  Switch,
  Grid,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSettings } from "store/settings/selectors";
import { WithLoading } from "components";
import { CouponType } from "typing/Coupon";
import _ from "lodash";
import {
  loadReferralSettings,
  updateReferralSettings,
} from "store/settings/thunks";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 300,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

const ReferralSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSettings();

  useEffect(() => {
    dispatch(loadReferralSettings());
  }, [dispatch]);

  return (
    <Card className={classes.root}>
      <WithLoading loading={settings.referralSettingsLoading}>
        {!settings.referralSettingsLoading && <ReferralSettingsForm />}
      </WithLoading>
    </Card>
  );
};

const ReferralSettingsForm = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const settings = useSettings();
  const { register, trigger, getValues, errors } = useForm({
    defaultValues: settings.referralSettings,
  });

  const handleSave = useCallback(async () => {
    if (await trigger()) {
      const values = getValues();
      dispatch(
        updateReferralSettings({
          ...values,
          discountPercent: Number(values.discountPercent),
          minOrderValue: Number(values.minOrderValue),
          maxDiscount: Number(values.maxDiscount),
          usageLimit: Number(values.usageLimit),
          validForDays: Number(values.validForDays),
        })
      );
    }
  }, [trigger, dispatch, getValues]);

  const hasError = (fieldName: string) => !!_.get(errors, fieldName);

  return (
    <form>
      <CardHeader
        subheader="Edit Referral Settings"
        title="Referral Settings"
      />
      <Divider />
      <CardContent>
        <FormControlLabel
          className={classes.marginBottom}
          control={
            <Switch
              name="enabled"
              inputRef={register}
              color="primary"
              defaultChecked={settings.referralSettings?.enabled ?? false}
            />
          }
          label="Enabled"
        />

        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              helperText="Please specify the coupon name"
              label="Coupon name"
              margin="dense"
              name="name"
              variant="outlined"
              inputRef={register({ required: true })}
              error={hasError("name")}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Select Type"
              margin="dense"
              name="type"
              error={hasError("type")}
              inputRef={register({ required: true })}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option value={CouponType.DISCOUNT}>Discount</option>
              <option value={CouponType.FREE_DELIVERY}>Free delivery</option>
            </TextField>
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Usage Limit"
              margin="dense"
              name="usageLimit"
              error={hasError("usageLimit")}
              type="number"
              inputRef={register({ required: true })}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Discount Percent"
              margin="dense"
              name="discountPercent"
              error={hasError("discountPercent")}
              type="number"
              inputRef={register({ required: true })}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Max Discount"
              margin="dense"
              name="maxDiscount"
              error={hasError("maxDiscount")}
              type="number"
              inputRef={register({ required: true })}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Minimum Order Value"
              margin="dense"
              name="minOrderValue"
              error={hasError("minOrderValue")}
              type="number"
              inputRef={register({ required: true })}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Valid for days"
              margin="dense"
              name="validForDays"
              error={hasError("validForDays")}
              type="number"
              inputRef={register({ required: true })}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <TextField
          className={classes.marginTop}
          name="referralMessage"
          inputRef={register}
          fullWidth
          variant="outlined"
          label="Referral Message"
          multiline
          rows={4}
          placeholder="Type referral message"
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" variant="outlined" onClick={handleSave}>
          Save
        </Button>
      </CardActions>
    </form>
  );
};

export default ReferralSettings;

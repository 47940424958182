import React, { useMemo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SettingsIcon from "@material-ui/icons/Settings";
import StoreIcon from "@material-ui/icons/Store";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import MessageIcon from "@material-ui/icons/Message";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import _ from "lodash";
import { Profile, SidebarNav } from "./components";
import { useUserLevel } from "store/auth/selectors";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const level = useUserLevel();

  const pages = useMemo(
    () => [
      ...(level.isOwner
        ? [
            {
              title: "Zones",
              href: "/zones",
              icon: <GroupWorkIcon />,
              sort: 2,
            },
            {
              title: "Admins",
              icon: <VerifiedUserIcon />,
              href: "admins",
              sort: 6,
            },
            {
              title: "Coupons",
              icon: <LocalOfferIcon />,
              href: "coupons",
              sort: 5,
            },
            {
              title: "Slides",
              href: "/slides",
              icon: <SlideshowIcon />,
              sort: 8,
            },
            {
              title: "Settings",
              href: "/settings",
              icon: <SettingsIcon />,
              sort: 10,
            },
          ]
        : []),
      {
        title: "Dashboard",
        href: "/dashboard",
        icon: <DashboardIcon />,
        sort: 1,
      },
      {
        title: "Stores",
        href: "/stores",
        icon: <StoreIcon />,
        sort: 3,
      },
      {
        title: "Orders",
        icon: <ShoppingCartIcon />,
        href: "orders",
        sort: 4,
      },
      {
        title: "Delivery Executives",
        icon: <RecentActorsIcon />,
        href: "dexs",
        sort: 7,
      },
      {
        title: "Push Notifications",
        icon: <MessageIcon />,
        href: "push-notifications",
        sort: 9,
      },
      {
        title: "Account",
        href: "/account",
        icon: <AccountBoxIcon />,
        sort: 11,
      },
    ],
    [level]
  );

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={_.orderBy(pages, "sort")} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ServerTimestamp, makeRefs } from "config/Firebase";
import Item from "typing/Item";
import { addRefToTrash } from "utils/firebase";
import { getStateFromThunkApi } from "utils/utils";

export const subscribeToItemList = createAction(
  "subscribeToItemList",
  (storeId: string) => ({ payload: storeId })
);
export const unsubscribeToItemList = createAction("unsubscribeToItemList");

export const setItemInStock = createAsyncThunk(
  "setStoreStatus",
  (
    { storeId, id, inStock }: Pick<Item, "id" | "inStock" | "storeId">,
    thunkApi
  ) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));

    refs.catalog
      .child(storeId)
      .child("item")
      .child(id)
      .update({ updatedAt: ServerTimestamp, inStock });
  }
);

export const addItem = createAsyncThunk(
  "addItem",
  async (item: Item, thunkApi) => {
    const storeId = item.storeId;
    delete item.storeId;
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    await refs.catalog
      .child(storeId)
      .child("item")
      .push({
        ...item,
        updatedAt: ServerTimestamp,
      });
  }
);

export const updateItem = createAsyncThunk(
  "updateItem",
  async (item: Item, thunkApi) => {
    const id = item.id;
    const storeId = item.storeId;
    delete item.id;
    delete item.storeId;
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    await refs.catalog
      .child(storeId)
      .child("item")
      .child(id)
      .update({
        ...item,
        updatedAt: ServerTimestamp,
      });
  }
);

export const deleteItem = createAsyncThunk(
  "deleteItem",
  async ({ storeId, id }: Pick<Item, "storeId" | "id">, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    const ref = refs.catalog.child(storeId).child("item").child(id);
    await ref.update({
      updatedAt: ServerTimestamp,
      deletedAt: ServerTimestamp,
    });
    await addRefToTrash(ref);
  }
);

import React, { useCallback, useState } from "react";
import UserForm from "../UserForm/UserForm";
import { useThunkDispatch } from "store";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useAdminItem } from "store/user/selectors";
import { updateUser } from "store/user/actions";

const AdminEdit: React.FC = () => {
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();
  const params = useParams<{ adminId: string }>();
  const [error, setError] = useState<string | undefined>(undefined);
  const handleErrorClose = useCallback(() => {
    setError(undefined);
  }, []);

  const admin = useAdminItem(params.adminId);

  const handleFinish = useCallback(
    async (values) => {
      const action = await thunkDispatch(
        updateUser({
          id: admin?.id,
          ...values,
        })
      );

      if (action.type === updateUser.rejected.toString()) {
        setError(action.payload);
      } else {
        history.goBack();
      }
    },
    [thunkDispatch, history, admin]
  );

  if (!admin) {
    return <Redirect to="/admins" />;
  }

  return (
    <UserForm
      title="Edit admin"
      subtitle={`Editing ${admin?.name}`}
      onFinish={handleFinish}
      defaultValues={admin}
      okText="Save"
      editing={true}
      error={error}
      onErrorClose={handleErrorClose}
    />
  );
};

export default AdminEdit;

import { useSelector } from "react-redux";
import { AppState } from "store";
import { useMemo } from "react";
import { filterArrayBySearch, filterDeletedItems } from "utils/utils";

export const useCategoryList = (storeId: string) => {
  return (
    useSelector((state: AppState) => state.categoryList.data[storeId]) ?? {
      list: [],
      loading: true,
    }
  );
};

export const useCategoryItem = (storeId: string, categoryId: string) => {
  const slice = useCategoryList(storeId);
  return useMemo(() => slice.list.find((it) => it.id === categoryId), [
    slice,
    categoryId,
  ]);
};

export const useFilteredCategory = (storeId: string) => {
  const slice = useCategoryList(storeId);
  const searchText = useSelector(
    (state: AppState) => state.categoryList.searchText
  );

  return useMemo(
    () =>
      filterArrayBySearch(filterDeletedItems(slice.list), searchText, "name"),
    [slice.list, searchText]
  );
};

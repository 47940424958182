import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getMaxUpdatedAtFromArray } from "utils/utils";
import Zone from "typing/Zone";

interface State {
  searchText: string;
  list: Zone[];
  loading: boolean;
  maxUpdatedAt: number;
}

const zoneSlice = createSlice({
  name: "zone",
  initialState: {
    searchText: "",
    list: [],
    loading: true,
    maxUpdatedAt: 0,
  } as State,
  reducers: {
    patchList: (state, action) => {
      state.list = _.unionBy(action.payload, state.list, "id");
      state.loading = false;
      state.maxUpdatedAt = getMaxUpdatedAtFromArray(
        state.maxUpdatedAt,
        action.payload
      );
    },
    setMaxUpdatedAt: (state, action) => {
      state.maxUpdatedAt = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
});

const { actions, reducer } = zoneSlice;

export { actions as zoneActions, reducer as zoneReducer };

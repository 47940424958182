import _ from "lodash";
import { AppState } from "store";

export function filterArrayBySearch<T>(
  list: T[],
  searchText: string,
  searchProp: string
): T[] {
  if (!searchText) {
    return list;
  }
  const regex = new RegExp(searchText, "ig");
  return list.filter((i) => regex.test(_.get(i, searchProp)));
}

export function getMaxUpdatedAtFromArray(
  defaultValue: number,
  list: Array<{ updatedAt: number }>
) {
  return Math.max(defaultValue, ...list.map((i) => i.updatedAt));
}

export function calculateChange(baseValue: number, currentValue: number) {
  const diff = currentValue - baseValue;
  const diffPercent = (diff / (baseValue || 1)) * 100;
  const absDiffPercent = Math.abs(diffPercent);
  const isPostive = absDiffPercent === diffPercent;
  return { absDiffPercent: absDiffPercent.toFixed(2), isPostive };
}

export function filterDeletedItems<T extends { deletedAt?: number }>(
  list: T[]
) {
  return list.filter((it) => !it.deletedAt);
}

export function getStateFromThunkApi(api: any) {
  return api.getState() as AppState;
}

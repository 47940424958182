import { takeLeading, put, takeEvery, take, select } from "redux-saga/effects";
import { subscribeToCouponList, unsubscribeFromCouponList } from "./actions";
import { eventChannel } from "redux-saga";
import { couponActions } from "./slice";
import Coupon from "typing/Coupon";
import { getArrayFromQuerySnapshot } from "utils/firebase";
import { Firestore } from "config/Firebase";
import { getMaxUpdatedAtFromArray } from "utils/utils";
import { AppState } from "store";

export function* couponListSubscriber() {
  yield takeLeading(subscribeToCouponList.type, listen);
}

function* listen() {
  const user = yield select((state: AppState) => state.auth.user);
  const list = yield select((state: AppState) => state.coupon.list);

  const maxUpdatedAt = getMaxUpdatedAtFromArray(0, list);

  const collection = Firestore.collection("coupon")
    .where("businessId", "==", user.businessId)
    .where("usageType", "==", "OPEN")
    .where("updatedAt", ">", maxUpdatedAt);

  const channel = eventChannel((emit) => {
    const unsubscribe = collection.onSnapshot((snapshot) => {
      emit({ snapshot });
    });

    return () => {
      unsubscribe();
    };
  });

  yield takeEvery(channel, function* ({ snapshot }) {
    const list = getArrayFromQuerySnapshot<Coupon & { id: string }>(snapshot);
    yield put(couponActions.patchList(list));
  });

  yield take(unsubscribeFromCouponList.type);
  return channel.close();
}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { SlideListTable, SlideListToolbar } from "./components";
import { Theme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { WithLoading } from "components";
import { useSlideList, useFilteredSlide } from "store/slide/selectors";
import {
  subscribeToSlideList,
  unsubscribeToSlideList,
} from "store/slide/actions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const SlideList = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const slide = useSlideList();

  const slideList = useFilteredSlide();

  useEffect(() => {
    dispatch(subscribeToSlideList());

    return () => {
      dispatch(unsubscribeToSlideList());
    };
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <WithLoading loading={slide.loading}>
        <SlideListToolbar />
        <div className={classes.content}>
          <SlideListTable slideList={slideList} />
        </div>
      </WithLoading>
    </div>
  );
};

export default SlideList;

import { createAsyncThunk } from "@reduxjs/toolkit";
import User from "../../typing/User";
import { Auth } from "../../config/Firebase";
import { getUserFromFirebaseUser } from "../../utils/auth";
import { unsubscribeToStoreList } from "store/store/actions";
import {
  unsubscribeFromExeList,
  unsubscribeFromAdminList,
} from "store/user/actions";
import { unsubscribeToOrderList } from "store/order/actions";
import { unsubscribeFromStats } from "store/stats/actions";
import { unsubscribeToConfig } from "store/settings/actions";

type Arg = { email: string; password: string };

export const startSignIn = createAsyncThunk<User, Arg>(
  "startSignIn",
  async ({ email, password }, thunkApi) => {
    try {
      const auth = await Auth.signInWithEmailAndPassword(email, password);
      return getUserFromFirebaseUser(auth.user!);
    } catch (error) {
      return Promise.reject(error.message || "Unknown error occured");
    }
  }
);

export const startSignOut = createAsyncThunk(
  "startSignOut",
  async (__, thunkApi) => {
    thunkApi.dispatch(unsubscribeToStoreList());
    thunkApi.dispatch(unsubscribeFromExeList());
    thunkApi.dispatch(unsubscribeToOrderList());
    thunkApi.dispatch(unsubscribeFromStats());
    thunkApi.dispatch(unsubscribeFromAdminList());
    thunkApi.dispatch(unsubscribeToConfig());
    await Auth.signOut();
  }
);

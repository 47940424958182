import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Theme,
  Button,
} from "@material-ui/core";

import usePagination from "hooks/Pagination";
import { Dialog } from "components";
import Slide from "typing/Slide";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDialog } from "components/Dialog/Dialog";
import { deleteSlide } from "store/slide/actions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  image: {
    objectFit: "cover",
    width: 120,
    height: 50,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const SlideListTable: React.FC<{ slideList: Array<Slide> }> = ({
  slideList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    startAt,
    endAt,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
  } = usePagination();

  const [targetSlide, setTargetSlide] = useState<Slide | null>(null);

  const dialog = useDialog(
    useCallback(
      (positive) => {
        if (positive) {
          dispatch(deleteSlide(targetSlide!));
        }
      },
      [dispatch, targetSlide]
    )
  );

  const handleDelete = useCallback(
    (slide) => () => {
      setTargetSlide(slide);
      dialog.show({
        title: "Are you sure?",
        message: "You want to delete this slide",
      });
    },
    [dialog]
  );

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {slideList.slice(startAt, endAt).map((slide) => (
                  <TableRow hover key={slide.id}>
                    <TableCell>{slide.position}</TableCell>
                    <TableCell>{slide.title}</TableCell>
                    <TableCell>
                      <img
                        className={classes.image}
                        src={slide.imageUrl}
                        alt={slide.title}
                      />
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        size="small"
                        component={Link}
                        to={`slides/${slide.id}/edit`}
                      >
                        Edit
                      </Button>
                      <Button size="small" onClick={handleDelete(slide)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={slideList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <Dialog {...dialog.props} showNegativeButton={true} />
    </Card>
  );
};

SlideListTable.propTypes = {
  slideList: PropTypes.array.isRequired,
};

export default SlideListTable;

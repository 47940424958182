import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { ZoneListTable, ZoneListToolbar } from "./components";
import { Theme } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { WithLoading } from "components";
import { subscribeToZoneList, unsubscribeToZoneList } from "store/zone/actions";
import { useZoneList, useFilteredZone } from "store/zone/selectors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const ZoneList = () => {
  const classes = useStyles();
  const zoneList = useZoneList();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToZoneList());
    return () => {
      dispatch(unsubscribeToZoneList());
    };
  }, [dispatch]);

  const listData = useFilteredZone();

  return (
    <div className={classes.root}>
      <WithLoading loading={zoneList.loading}>
        <ZoneListToolbar />
        <div className={classes.content}>
          <ZoneListTable list={listData} />
        </div>
      </WithLoading>
    </div>
  );
};

export default ZoneList;

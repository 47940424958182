import { takeLeading, put, takeEvery, take, select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { getObjectFromSnapshot } from "utils/firebase";
import { AppState } from "store";
import { subscribeToConfig, unsubscribeToConfig } from "./actions";
import { settingsActions } from "./slice";
import { Refs, selectRefs } from "config/Firebase";

export function* settingSubscriber() {
  yield takeLeading(subscribeToConfig.type, listen);
}

function* listen() {
  const updatedAt = yield select(
    (state: AppState) => state.settings.configUpdatedAt
  );
  const refs: Refs = yield selectRefs();

  const channel = eventChannel((emit) => {
    const ref = refs.businessConfig
      .orderByChild("updatedAt")
      .startAt(updatedAt + 1);

    ref.on("value", (snapshot) => {
      emit({ snapshot });
    });

    return () => {
      ref.off();
    };
  });

  yield takeEvery(channel, function* ({ snapshot }) {
    const data = getObjectFromSnapshot<any>(snapshot);
    yield put(settingsActions.setConfig(data.config));
  });

  yield take(unsubscribeToConfig.type);
  return channel.close();
}

import { useSelector } from "react-redux";
import { AppState } from "store";
import { useMemo } from "react";
import { filterArrayBySearch, filterDeletedItems } from "utils/utils";

export const useSlideList = () => useSelector((state: AppState) => state.slide);

export const useSlideItem = (slideId: string) => {
  const slideList = useSlideList();

  return useMemo(() => slideList.list.find((slide) => slide.id === slideId), [
    slideList,
    slideId,
  ]);
};

export const useFilteredSlide = () => {
  const slide = useSlideList();

  return useMemo(
    () =>
      filterArrayBySearch(
        filterDeletedItems(slide.list),
        slide.searchText,
        "title"
      ),
    [slide.list, slide.searchText]
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { startSignIn, startSignOut } from "./actions";
import User from "../../typing/User";

interface AuthState {
  user?: User;
  loggedIn: boolean;
  determined: boolean;
  loading: boolean;
  error?: string;
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loggedIn: false,
    loading: false,
  } as AuthState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startSignIn.pending, (state) => {
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(startSignIn.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.loggedIn = true;
      state.error = undefined;
    });
    builder.addCase(startSignIn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(startSignOut.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(startSignOut.fulfilled, (state) => {
      state.loading = false;
      state.user = undefined;
      state.loggedIn = false;
      state.error = undefined;
    });
  },
});

const { actions, reducer } = authSlice;

export { actions as authActions, reducer as authReducer };

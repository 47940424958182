import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Theme,
  Container,
  Button,
} from "@material-ui/core";
import { WithLoading } from "components";
import { subscribeToOrderList } from "store/order/actions";
import { useDispatch } from "react-redux";
import { useFilteredOrder, useOrderList } from "store/order/selectors";
import OrderListTable from "./components/OrderListTable/OrderListTable";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  content: {
    padding: 0,
  },
}));

const OrderListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToOrderList());
  }, [dispatch]);

  const orders = useFilteredOrder();
  const { loading } = useOrderList();

  return (
    <WithLoading loading={loading}>
      <Container className={classes.root}>
        <Card>
          <CardHeader
            title={`Orders (${orders.length})`}
            action={
              <Button
                color="primary"
                size="small"
                variant="outlined"
                component={Link}
                to="/orders/completed"
              >
                Completed orders
              </Button>
            }
          />
          <Divider />
          <CardContent className={classes.content}>
            <OrderListTable orders={orders} showMoreOptions={true} />
          </CardContent>
        </Card>
      </Container>
    </WithLoading>
  );
};

export default OrderListView;

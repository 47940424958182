import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Theme,
  Button,
} from "@material-ui/core";

import usePagination from "hooks/Pagination";
import { Link } from "react-router-dom";
import Zone from "typing/Zone";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const ZoneListTable: React.FC<{
  list: Array<Zone>;
}> = ({ list }) => {
  const classes = useStyles();

  const {
    startAt,
    endAt,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
  } = usePagination();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(startAt, endAt).map((it, index) => (
                  <TableRow hover key={it.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{it.name}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="text"
                        size="small"
                        component={Link}
                        to={`/zones/${it.id}/edit`}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={list.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default ZoneListTable;

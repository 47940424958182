import React, { useEffect } from "react";
import UserList from "../UserList";
import { useFilteredDex, useExeListLoading } from "store/user/selectors";
import { subscribeToExeList } from "store/user/actions";
import { useDispatch } from "react-redux";

const DexList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToExeList());
  }, [dispatch]);

  const list = useFilteredDex();

  const loading = useExeListLoading();

  return (
    <UserList
      list={list}
      makeEditUrl={(it) => `dexs/${it.id}/edit`}
      addText="Add Delivery Executive"
      addUrl="dexs/add"
      searchHint="Search delivery executive"
      loading={loading}
    />
  );
};

export default DexList;

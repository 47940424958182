import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";
import { SearchInput } from "components";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "store/user/slice";
import { AppState } from "store";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

export interface UserListToolbarProps {
  addText: string;
  addUrl: string;
  searchHint: string;
}

const UserListToolbar: React.FC<UserListToolbarProps> = ({
  addText,
  addUrl,
  searchHint,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const searchText = useSelector((state: AppState) => state.user.searchText);

  const handleChange = useCallback(
    (event) => {
      dispatch(userActions.setSearchText(event.target.value));
    },
    [dispatch]
  );

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to={addUrl}
        >
          {addText}
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder={searchHint}
          onChange={handleChange}
          defaultValue={searchText}
        />
      </div>
    </div>
  );
};

export default UserListToolbar;

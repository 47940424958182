import User from "../typing/User";

export const getUserFromFirebaseUser = async (
  user: firebase.User
): Promise<User> => {
  const idToken = await user!.getIdTokenResult();
  return {
    id: user.uid,
    name: user?.displayName,
    email: user?.email,
    phone: user?.phoneNumber,
    accessToken: idToken?.token,
    zoneId: idToken.claims.zoneId,
    businessId: idToken.claims.businessId,
    level: idToken.claims.level,
  } as User;
};

import React from "react";
import { Route, Redirect } from "react-router-dom";

export interface RouteWithLayoutProps {
  component: React.ComponentType;
  layout: React.ComponentType;
  path: string;
  protection?: boolean;
}

const RouteWithLayout: React.FC<RouteWithLayoutProps> = (props) => {
  const { layout: Layout, component: Component, protection, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps: any) =>
        protection !== false ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

export default RouteWithLayout;

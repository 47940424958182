import React, { useState, useMemo, useCallback } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  TableBody,
  makeStyles,
  Theme,
} from "@material-ui/core";
import moment from "moment";
import { PopupMenu } from "components";
import PerfectScrollbar from "react-perfect-scrollbar";
import Order, { Status } from "typing/Order";
import _ from "lodash";
import OrderStatusDialog from "../OrderStatusDialog/OrderStatusDialog";
import DexSelectionDialog from "../DexSelectionDialog/DexSelectionDialog";
import User from "typing/User";
import { updateOrderToConfirmed, updateOrderStatus } from "store/order/thunks";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import OrderStatusBullet from "../OrderStatusBullet";

interface Props {
  orders: Array<Order>;
  showMoreOptions?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: 440,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
}));

const OrderListTable: React.FC<Props> = ({ orders, showMoreOptions }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [orderUpdate, setOrderUpdate] = useState<Order | null>(null);
  const [orderUpdateDialogOpen, setOrderUpdateDialogOpen] = useState(false);
  const [dexDialogOpen, setDexDialogOpen] = useState(false);

  const [sort, setSort] = useState<{
    field: "time" | "status";
    order: "desc" | "asc";
  }>({
    field: "time",
    order: "desc",
  });

  const sortedOrders = useMemo(
    () => _.orderBy(orders, sort.field, sort.order),
    [orders, sort]
  );

  const handleMoreOptions = useCallback(
    (order: Order) => (option: string) => {
      if (option === "Update Order") {
        setOrderUpdate(order);
        setOrderUpdateDialogOpen(true);
      } else if (option === "View Order") {
        history.push(`/orders/${order.orderId}`);
      }
    },
    [history]
  );

  const handleSortChange = useCallback(
    (field) => () => {
      setSort({
        field,
        order: sort.order === "asc" ? "desc" : "asc",
      });
    },
    [sort]
  );

  const handleDexSelection = useCallback(
    (dex?: User) => {
      if (dex && orderUpdate) {
        dispatch(updateOrderToConfirmed({ orderId: orderUpdate.orderId, dex }));
      }
      setDexDialogOpen(false);
    },
    [dispatch, orderUpdate]
  );

  const handleUpdateOrderDialogClose = useCallback(
    (status) => {
      if (orderUpdate && status !== orderUpdate.status) {
        if (status === Status.CONFIRMED) {
          setDexDialogOpen(true);
        } else {
          dispatch(updateOrderStatus({ orderId: orderUpdate.orderId, status }));
        }
      }
      setOrderUpdateDialogOpen(false);
    },
    [orderUpdate, dispatch]
  );

  return (
    <PerfectScrollbar>
      <div className={classes.inner}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Store</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell sortDirection={sort.order}>
                  <Tooltip enterDelay={300} title="Sort by date">
                    <TableSortLabel
                      active={sort.field === "time"}
                      direction={sort.order}
                      onClick={handleSortChange("time")}
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell sortDirection={sort.order}>
                  <Tooltip enterDelay={300} title="Sort by status">
                    <TableSortLabel
                      active={sort.field === "status"}
                      direction={sort.order}
                      onClick={handleSortChange("status")}
                    >
                      Status
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>Bill Amount</TableCell>
                {showMoreOptions && <TableCell align="center">More</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedOrders.map((order) => (
                <TableRow hover key={order.orderId}>
                  <TableCell>{order.orderId}</TableCell>
                  <TableCell>{order.store.name}</TableCell>
                  <TableCell>{order.customer.name}</TableCell>
                  <TableCell>
                    {moment(order.time).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    <OrderStatusBullet status={order.status} />
                  </TableCell>
                  <TableCell>₹{order.billAmount}</TableCell>
                  {showMoreOptions && (
                    <TableCell align="center">
                      <PopupMenu
                        options={["View Order", "Update Order"]}
                        onSelect={handleMoreOptions(order)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <OrderStatusDialog
        id="order-status"
        keepMounted={true}
        value={orderUpdate?.status || Status.PLACED}
        open={orderUpdateDialogOpen}
        onClose={handleUpdateOrderDialogClose}
      />
      <DexSelectionDialog open={dexDialogOpen} onClose={handleDexSelection} />
    </PerfectScrollbar>
  );
};

export default OrderListTable;

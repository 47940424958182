import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { Provider } from "react-redux";
import store, { browserHistory } from "./store";
import { ConnectedRouter } from "connected-react-router";
import { startSignIn } from "./store/auth/actions";
import { getUserFromFirebaseUser } from "./utils/auth";
import { Auth } from "config/Firebase";
import { UserLevel } from "typing/User";

ReactDOM.render(
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      height: "100vh",
    }}
  >
    <img
      src="https://d33wubrfki0l68.cloudfront.net/458412ef0894476c376deb206e089b27160f46ba/3e0e5/images/loading.gif"
      alt="Loading"
    />
  </div>,
  document.getElementById("root")
);

let hasRendered = false;

const render = () => {
  if (!hasRendered) {
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={browserHistory}>
          <App />
        </ConnectedRouter>
      </Provider>,
      document.getElementById("root")
    );
    hasRendered = true;
  }
};

const publicUrls = ["/onboarding"];

Auth.onAuthStateChanged(async (fbUser) => {
  const pathname = browserHistory.location.pathname;
  if (fbUser) {
    const user = await getUserFromFirebaseUser(fbUser);

    if (
      (user.level === UserLevel.OWNER || user.level === UserLevel.ADMIN) &&
      user.zoneId
    ) {
      store.dispatch(startSignIn.fulfilled(user));
      render();
      if (publicUrls.includes(pathname) || pathname === "/sign-in") {
        browserHistory.push("/dashboard");
      }
    } else {
      await Auth.signOut();
      store.dispatch(
        startSignIn.rejected(
          new Error("You don't have proper permissions to sign in")
        )
      );
    }
  } else {
    render();
    if (!publicUrls.includes(pathname) && pathname !== "/sign-in") {
      browserHistory.push("/sign-in");
    }
  }
});

serviceWorker.unregister();

import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  TextField,
  Divider,
  Theme,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loadOrderSettings, updateOrderSettings } from "store/settings/thunks";
import { useSettings } from "store/settings/selectors";
import { WithLoading } from "components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 300,
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));

const OrderSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSettings();

  useEffect(() => {
    dispatch(loadOrderSettings());
  }, [dispatch]);

  return (
    <Card className={classes.root}>
      <WithLoading loading={settings.orderSettingsLoading}>
        {!settings.orderSettingsLoading && <OrderSettingsForm />}
      </WithLoading>
    </Card>
  );
};

const OrderSettingsForm = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const settings = useSettings();
  const { register, trigger, getValues, errors } = useForm({
    defaultValues: settings.orderSettings,
  });

  const handleSave = useCallback(async () => {
    if (await trigger()) {
      const values = getValues();
      dispatch(
        updateOrderSettings({
          ...values,
          mininumOrder: Number(values.mininumOrder),
          standardDeliveryCharges: Number(values.standardDeliveryCharges),
        })
      );
    }
  }, [trigger, dispatch, getValues]);

  return (
    <form>
      <CardHeader subheader="Edit Order Settings" title="Order Settings" />
      <Divider />
      <CardContent>
        <FormControlLabel
          className={classes.item}
          control={
            <Switch
              name="deliveryChargesEnabled"
              inputRef={register}
              color="primary"
              defaultChecked={settings.orderSettings?.deliveryChargesEnabled}
            />
          }
          label="Delivery Charges Enabled"
        />
        <TextField
          className={classes.item}
          name="standardDeliveryCharges"
          inputRef={register({ required: true, min: 0 })}
          fullWidth
          type="number"
          variant="outlined"
          label="Standard Delivery Charges"
          placeholder="Type number"
          error={!!errors.standardDeliveryCharges}
        />
        <TextField
          className={classes.item}
          name="mininumOrder"
          inputRef={register({ required: true, min: 0 })}
          fullWidth
          type="number"
          variant="outlined"
          label="Minimun Order Value"
          placeholder="Type number"
          error={!!errors.mininumOrder}
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" variant="outlined" onClick={handleSave}>
          Save
        </Button>
      </CardActions>
    </form>
  );
};

export default OrderSettings;

import React, { useCallback } from "react";
import CategoryForm from "../CategoryForm/CategoryForm";
import { useThunkDispatch } from "store";
import { useHistory, useParams } from "react-router-dom";
import { addCategory } from "store/category/actions";

const CategoryAdd: React.FC = () => {
  const thunkDispatch = useThunkDispatch();

  const history = useHistory();
  const params = useParams<{ storeId: string }>();

  const handleFinish = useCallback(
    async (values) => {
      try {
        await thunkDispatch(
          addCategory({ ...values, storeId: params.storeId })
        );
        history.goBack();
      } catch (error) {
        console.log(error);
        // TODO: handle
      }
    },
    [thunkDispatch, history, params]
  );

  return (
    <CategoryForm
      title="Add Category"
      subtitle="Create new category"
      onFinish={handleFinish}
    />
  );
};

export default CategoryAdd;

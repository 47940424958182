import React, { useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";

import { SearchInput } from "components";
import { useDispatch, useSelector } from "react-redux";
import { storeActions } from "store/store/slice";
import { AppState } from "store";
import { useSelectedStoreIds } from "store/app/selectors";
import { setStoreListStatus } from "store/store/actions";
import { appActions } from "store/app/slice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

const StoreListToolbar: React.FC<any> = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const searchText = useSelector((state: AppState) => state.store.searchText);

  const selectedStoreIds = useSelectedStoreIds();

  const handleChange = useCallback(
    (event) => {
      dispatch(storeActions.setSearchText(event.target.value));
    },
    [dispatch]
  );

  const setSelectedStoreListStatus = (status: number) => () => {
    dispatch(
      setStoreListStatus(selectedStoreIds.map((id) => ({ id, status })))
    );
    dispatch(appActions.setSelectedStoreIds([]));
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {selectedStoreIds.length > 0 && (
          <React.Fragment>
            <Button
              color="default"
              variant="contained"
              className={classes.button}
              onClick={setSelectedStoreListStatus(1)}
            >
              Open Selected
            </Button>
            <Button
              className={classes.button}
              color="default"
              variant="contained"
              onClick={setSelectedStoreListStatus(0)}
            >
              Close Selected
            </Button>
          </React.Fragment>
        )}
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/stores/add"
        >
          Add store
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search store"
          defaultValue={searchText}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

StoreListToolbar.propTypes = {
  className: PropTypes.string,
};

export default StoreListToolbar;

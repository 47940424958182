import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Theme,
  FormControlLabel,
  Switch,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useConfig, useConfigLoading } from "store/settings/selectors";
import { WithLoading } from "components";
import { updateOtherSettings } from "store/settings/thunks";
import { subscribeToConfig } from "store/settings/actions";
import { useFilteredStore } from "store/store/selectors";
import { subscribeToStoreList } from "store/store/actions";
import { themes } from "appContants";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 300,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  themeHint: {
    display: "inline",
    border: "1px black solid",
    borderRadius: 100,
    margin: theme.spacing(1),
    width: 30,
    height: 30,
  },
}));

const OtherSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useConfigLoading();

  useEffect(() => {
    dispatch(subscribeToConfig());
  }, [dispatch]);

  return (
    <Card className={classes.root}>
      <WithLoading loading={loading}>
        {!loading && <OtherSettingsForm />}
      </WithLoading>
    </Card>
  );
};

const OtherSettingsForm = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const config = useConfig();
  const { register, trigger, getValues, control, watch } = useForm({
    defaultValues: {
      mainStoreId: config?.mainStoreId,
      multiStore: config?.type === "multiple",
      theme: config?.theme?.primaryColor,
    },
  });

  const storeList = useFilteredStore();

  useEffect(() => {
    dispatch(subscribeToStoreList());
  }, [dispatch]);

  const handleSave = useCallback(async () => {
    if (await trigger()) {
      const values = getValues();

      dispatch(
        updateOtherSettings({
          mainStoreId: values.mainStoreId ?? "",
          type: values.multiStore ? "multiple" : "single",
          theme:
            themes.find((theme) => values.theme === theme.data.primaryColor)
              ?.data! ?? {},
        })
      );
    }
  }, [trigger, dispatch, getValues]);

  return (
    <form>
      <CardHeader subheader="Edit Other Settings" title="Other Settings" />
      <Divider />
      <CardContent>
        <FormControlLabel
          className={classes.marginBottom}
          control={
            <Switch
              name="multiStore"
              inputRef={register}
              color="primary"
              defaultChecked={config?.type === "multiple"}
            />
          }
          label="Multi store"
        />
        <Box mb={2}>
          <FormControl variant="outlined">
            <InputLabel>Main Store</InputLabel>
            <Controller
              name="mainStoreId"
              control={control}
              defaultValue={config?.mainStoreId}
              as={
                <Select disabled={watch("multiStore")} style={{ width: 240 }}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {storeList.map((store) => (
                    <MenuItem value={store.id} key={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Box>

        <Box>
          <FormControl variant="outlined">
            <InputLabel>Theme Color</InputLabel>
            <Controller
              name="theme"
              control={control}
              defaultValue={config?.theme?.primaryColor}
              as={
                <Select style={{ width: 240 }}>
                  {themes.map((value) => (
                    <MenuItem
                      value={value.data.primaryColor}
                      key={value.data.primaryColor}
                    >
                      <span
                        className={classes.themeHint}
                        style={{ backgroundColor: value.data.primaryColor }}
                      />
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" variant="outlined" onClick={handleSave}>
          Save
        </Button>
      </CardActions>
    </form>
  );
};

export default OtherSettings;

import React, { useCallback, useState } from "react";
import { useThunkDispatch } from "store";
import { useHistory, useParams } from "react-router-dom";
import { addItem } from "store/item/actions";
import ItemForm from "../ItemForm/ItemForm";
import { uploadFile } from "store/actions";

const ItemAdd: React.FC = () => {
  const thunkDispatch = useThunkDispatch();

  const history = useHistory();
  const params = useParams<{ storeId: string }>();

  const [image, setImage] = useState<Blob | null>(null);

  const handleFinish = useCallback(
    async (values) => {
      try {
        let imageUri = null;
        if (image) {
          const imageUpload = await thunkDispatch(
            uploadFile({
              path: `images/store/${params.storeId}/items`,
              file: image,
            })
          );
          setImage(null);
          imageUri = imageUpload.payload;
        }

        await thunkDispatch(
          addItem({ ...values, imageUrl: imageUri, storeId: params.storeId })
        );
        history.goBack();
      } catch (error) {
        console.log(error);
        // TODO: handle
      }
    },
    [thunkDispatch, history, params, image]
  );

  const handleImageSelect = useCallback((file) => {
    if (file.size > 15 * 1024) {
      return "Image size should be less then 15kb";
    }

    setImage(file);
    return null;
  }, []);

  return (
    <ItemForm
      title="Add Item"
      subtitle="Create new item"
      onImageSelect={handleImageSelect}
      onFinish={handleFinish}
    />
  );
};

export default ItemAdd;

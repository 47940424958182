import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { WithLoading } from "components";
import { useConfig, useConfigLoading } from "store/settings/selectors";
import _ from "lodash";
import { useDispatch } from "react-redux";
import PaymentIcon from "@material-ui/icons/Payment";
import EditIcon from "@material-ui/icons/Edit";
import { PaymentMode } from "typing/BusinessConfig";
import { useForm } from "react-hook-form";
import { updatePaymentMode } from "store/settings/thunks";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 300,
  },
}));

const PaymentModes = () => {
  const classes = useStyles();
  const loading = useConfigLoading();
  const config = useConfig();
  const dispatch = useDispatch();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [
    editingPaymentMode,
    setEditingPaymentMode,
  ] = useState<PaymentMode | null>(null);

  const handleUpdate = useCallback(
    (paymentMode?: PaymentMode) => {
      setEditDialogOpen(false);

      if (paymentMode) {
        dispatch(updatePaymentMode(paymentMode));
      }
    },
    [dispatch]
  );

  const handleEdit = useCallback(
    (paymentMode: PaymentMode) => () => {
      setEditDialogOpen(true);
      setEditingPaymentMode(paymentMode);
    },
    []
  );

  const paymentModes = _.values(config?.paymentModes ?? []);

  return (
    <Card className={classes.root}>
      <WithLoading loading={loading}>
        <List component="nav" aria-label="main mailbox folders">
          {paymentModes.map((p) => (
            <ListItem key={p.id} button>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText
                primary={p.title}
                secondary={p.enabled ? "Enabled" : "Disabled"}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={handleEdit(p)}
                >
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </WithLoading>
      {editingPaymentMode && (
        <PaymentModeDialog
          open={editDialogOpen}
          paymentMode={editingPaymentMode}
          onClose={handleUpdate}
        />
      )}
    </Card>
  );
};

function PaymentModeDialog({
  paymentMode,
  open,
  onClose,
}: {
  paymentMode: PaymentMode;
  open: boolean;
  onClose: (paymentMode?: PaymentMode) => void;
}) {
  const { register, watch, trigger, getValues, errors } = useForm();

  const handleClose = () => {
    onClose();
  };

  const handleSave = useCallback(async () => {
    if (await trigger()) {
      const values = getValues() as any;
      onClose({ id: paymentMode.id, ...values });
    }
  }, [trigger, getValues, onClose, paymentMode]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{paymentMode.title}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={paymentMode.enabled}
              inputRef={register}
              name="enabled"
              color="primary"
            />
          }
          label="Enabled"
        />
        <TextField
          autoFocus
          margin="dense"
          label="Disable Reason"
          type="text"
          fullWidth
          name="disableReason"
          inputRef={register}
          disabled={watch("enabled")}
          defaultValue={paymentMode.disableReason}
        />
        {paymentMode.type === "upi" && (
          <React.Fragment>
            <TextField
              autoFocus
              margin="dense"
              label="Receiver name"
              type="text"
              fullWidth
              name="upiInfo.name"
              inputRef={register({ required: true })}
              defaultValue={paymentMode.upiInfo?.name}
              error={!!_.get(errors, "upiInfo.name")}
              helperText={
                !!_.get(errors, "upiInfo.name")
                  ? "Please enter reciver name"
                  : ""
              }
            />
            <TextField
              margin="dense"
              label="Receiver UPI Address"
              type="text"
              fullWidth
              name="upiInfo.upiId"
              inputRef={register({ required: true })}
              defaultValue={paymentMode.upiInfo?.upiId}
              error={!!_.get(errors, "upiInfo.upiId")}
              helperText={
                !!_.get(errors, "upiInfo.upiId")
                  ? "Please enter valid UPI Address"
                  : ""
              }
            />
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentModes;

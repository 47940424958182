import { createAsyncThunk } from "@reduxjs/toolkit";
import { FirebaseStorage } from "config/Firebase";
import _ from "lodash";
import * as uuid from "uuid";
import axios from "axios";
import { AppState } from "store";
import { getStateFromThunkApi } from "utils/utils";

const baseUrl = process.env.REACT_APP_API_URL;

export const uploadFile = createAsyncThunk(
  "uploadFile",
  async ({ path, file }: { path: string; file: Blob }, thunkApi) => {
    try {
      const state = getStateFromThunkApi(thunkApi);
      const filename: string = _.get(file, "name");
      const ext = filename.substring(
        filename.lastIndexOf(".") || filename.length,
        filename.length
      );

      const task = await FirebaseStorage.ref(
        `business/${state.auth.user?.businessId}/${path}/${uuid.v4()}${ext}`
      ).put(file);
      return task.ref.getDownloadURL();
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const deleteFile = createAsyncThunk(
  "deleteFile",
  async (fileUrl: string) => {
    try {
      await FirebaseStorage.refFromURL(fileUrl).delete();
    } catch (error) {
      console.error("deleteFile", error);
    }
  }
);

export const sendPushNotification = createAsyncThunk(
  "sendPushNotification",
  async (notifcation: { title: string; message: string }, thunkApi) => {
    try {
      const response = await axios.post(
        `${baseUrl}/sendPushNotification`,
        notifcation,
        {
          headers: getAuthHeaders(getStateFromThunkApi(thunkApi) as AppState),
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

function getAuthHeaders(state: AppState) {
  return {
    authorization: `Bearer ${state.auth.user?.accessToken}`,
  };
}

import React, { useState, useCallback } from "react";
import SlideForm from "../SlideForm/SlideForm";
import { useThunkDispatch } from "store";
import { uploadFile } from "store/actions";
import { useHistory } from "react-router-dom";
import { addSlide } from "store/slide/actions";

const SlideAdd: React.FC = () => {
  const thunkDispatch = useThunkDispatch();
  const [image, setImage] = useState<Blob | null>(null);
  const history = useHistory();

  const handleImageSelect = useCallback((file) => {
    if (file.size > 15 * 1024) {
      return "Image size should be less then 15kb";
    }

    setImage(file);
    return null;
  }, []);

  const handleFinish = useCallback(
    async (values) => {
      try {
        let imageUrl;
        if (image) {
          const imageUpload = await thunkDispatch(
            uploadFile({ path: "images/slides", file: image })
          );
          setImage(null);
          imageUrl = imageUpload.payload;
        }
        await thunkDispatch(
          addSlide({
            ...values,
            ...(imageUrl && { imageUrl }),
          })
        );
        history.goBack();
      } catch (error) {
        console.log(error);
        // TODO: handle
      }
    },
    [image, thunkDispatch, history]
  );

  return (
    <SlideForm
      title="Add Slide"
      subtitle="Creating new slide"
      onImageSelect={handleImageSelect}
      onFinish={handleFinish}
      okText="Add"
    />
  );
};

export default SlideAdd;

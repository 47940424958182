import Store from "typing/Store";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getMaxUpdatedAtFromArray } from "utils/utils";
import { unsubscribeToStoreList } from "./actions";

interface StoreState {
  searchText: string;
  list: Store[];
  loading: boolean;
  maxUpdatedAt: number;
}

const storeSlice = createSlice({
  name: "store",
  initialState: {
    searchText: "",
    list: [],
    loading: true,
    maxUpdatedAt: 0,
  } as StoreState,
  reducers: {
    patchList: (state, action) => {
      state.list = _.unionBy(action.payload, state.list, "id");
      state.loading = false;
      state.maxUpdatedAt = getMaxUpdatedAtFromArray(
        state.maxUpdatedAt,
        action.payload
      );
    },

    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(unsubscribeToStoreList.type, (state) => {
      state.list = [];
      state.loading = true;
      state.searchText = "";
    });
  },
});

const { actions, reducer } = storeSlice;

export { actions as storeActions, reducer as storeReducer };

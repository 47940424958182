import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Theme,
  Button,
  Switch,
} from "@material-ui/core";

import usePagination from "hooks/Pagination";
import { Link } from "react-router-dom";
import Item from "typing/Item";
import { setItemInStock, deleteItem } from "store/item/actions";
import { useDispatch } from "react-redux";
import { Dialog } from "components";
import { useDialog } from "components/Dialog/Dialog";
import { useCategoryList } from "store/category/selectors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const ItemListTable: React.FC<{
  storeId: string;
  itemList: Array<Item>;
}> = ({ storeId, itemList }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [targetItem, setTargetItem] = useState<Item | null>(null);

  const dialog = useDialog(
    useCallback(
      (positive) => {
        if (positive) {
          dispatch(deleteItem({ id: targetItem!.id, storeId }));
        }
      },
      [targetItem, storeId, dispatch]
    )
  );

  const categories = useCategoryList(storeId).list;

  const handleDelete = useCallback(
    (item) => () => {
      setTargetItem(item);
      dialog.show({
        title: "Are you sure?",
        message: "You want to delete this item",
      });
    },
    [dialog]
  );

  const handleInStockChange = useCallback(
    (item) => (event: any) => {
      dispatch(
        setItemInStock({ storeId, id: item.id, inStock: event.target.checked })
      );
    },
    [dispatch, storeId]
  );

  const {
    startAt,
    endAt,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
  } = usePagination();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell>Available</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemList.slice(startAt, endAt).map((item) => (
                  <TableRow hover key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {categories?.find((c) => c.id === item.categoryId)
                        ?.name ?? "No category"}
                    </TableCell>
                    <TableCell>{item.tags}</TableCell>
                    <TableCell>
                      <Switch
                        color="primary"
                        checked={item.inStock}
                        onChange={handleInStockChange(item)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="text"
                        size="small"
                        component={Link}
                        to={`/stores/${storeId}/items/${item.id}/edit`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="text"
                        size="small"
                        onClick={handleDelete(item)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={itemList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <Dialog {...dialog.props} showNegativeButton={true} />
    </Card>
  );
};

ItemListTable.propTypes = {
  itemList: PropTypes.array.isRequired,
};

export default ItemListTable;

import { createSlice } from "@reduxjs/toolkit";

interface AppState {
  catalogTab: number;
  selectedStoreIds: string[];
  onboardingUserToken?: string;
}

const appSlice = createSlice({
  name: "app",
  initialState: {
    catalogTab: 0,
    selectedStoreIds: [],
  } as AppState,
  reducers: {
    setCatalogTab: (state, action) => {
      state.catalogTab = action.payload;
    },
    setSelectedStoreIds: (state, action) => {
      state.selectedStoreIds = action.payload;
    },
    setOnboardinguserToken: (state, action) => {
      state.onboardingUserToken = action.payload;
    },
  },
});

const { actions, reducer } = appSlice;

export { actions as appActions, reducer as appReducer };

import { takeLeading, put, takeEvery, take, select } from "redux-saga/effects";
import { subscribeToCategoryList, unsubscribeToCategoryList } from "./actions";
import { eventChannel } from "redux-saga";
import { categoryListActions } from "./slice";
import { getListFromSnapshot } from "utils/firebase";
import { AppState } from "store";
import Category from "typing/Category";
import { getMaxUpdatedAtFromArray } from "utils/utils";
import { Refs, selectRefs } from "config/Firebase";

export function* categoryListSubscriber() {
  yield takeLeading(subscribeToCategoryList.type, listen);
}

function* listen(action: any) {
  const storeId = action.payload;
  const categoryList = yield select(
    (state: AppState) => state.categoryList.data[storeId]
  );
  const maxUpdatedAt = getMaxUpdatedAtFromArray(0, categoryList?.list ?? []);

  const refs: Refs = yield selectRefs();

  const channel = eventChannel((emit) => {
    const ref = refs.catalog
      .child(storeId)
      .child("category")
      .orderByChild("updatedAt")
      .startAt(maxUpdatedAt + 1);

    ref.on("value", (snapshot) => {
      emit({ snapshot });
    });

    return () => {
      ref.off();
    };
  });

  yield takeEvery(channel, function* ({ snapshot }) {
    const list = getListFromSnapshot<Category>(snapshot);
    yield put(categoryListActions.patchList({ list, storeId }));
  });

  yield take(unsubscribeToCategoryList.type);
  return channel.close();
}

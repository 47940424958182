import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { useDexList, useExeListLoading } from "store/user/selectors";
import User from "typing/User";
import { useDispatch } from "react-redux";
import { subscribeToExeList } from "store/user/actions";
import { WithLoading } from "components";

export interface DexSelectionDialogProps {
  open: boolean;
  onClose: (value?: User) => void;
}

export default function DexSelectionDialog(props: DexSelectionDialogProps) {
  const { onClose, open } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(subscribeToExeList());
    }
  }, [open, dispatch]);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (dex: User) => {
    onClose(dex);
  };

  const loading = useExeListLoading();

  const dexs = useDexList();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Select delivery executive
      </DialogTitle>

      <WithLoading loading={loading}>
        <List>
          {dexs.map((it) => (
            <ListItem
              button
              onClick={() => handleListItemClick(it)}
              key={it.id}
            >
              <ListItemText primary={it.name} secondary={it.phone} />
            </ListItem>
          ))}
        </List>
      </WithLoading>
    </Dialog>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { UserListTable, UserListToolbar } from "./components";
import { Theme } from "@material-ui/core";
import { WithLoading } from "components";
import { UserListTableProps } from "./components/UserListTable/UserListTable";
import { UserListToolbarProps } from "./components/UserListToolbar/UserListToolbar";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type Props = UserListTableProps & UserListToolbarProps & { loading: boolean };

const UserList: React.FC<Props> = ({
  list,
  makeEditUrl,
  loading,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <WithLoading loading={loading}>
        <UserListToolbar {...restProps} />
        <div className={classes.content}>
          <UserListTable list={list} makeEditUrl={makeEditUrl} />
        </div>
      </WithLoading>
    </div>
  );
};

export default UserList;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Theme,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {
  useThisMonthOrderStat,
  useLastMonthOrderStat,
  useStats,
} from "store/stats/selectors";
import { WithLoading } from "components";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import clsx from "clsx";
import { calculateChange } from "utils/utils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  improvedColor: {
    color: theme.palette.success.dark,
  },
  worsenColor: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    marginRight: theme.spacing(1),
  },
}));

const Budget = () => {
  const classes = useStyles();

  const stats = useStats();

  const thisMonth = useThisMonthOrderStat();
  const lastMonth = useLastMonthOrderStat();

  const { isPostive, absDiffPercent } = calculateChange(
    lastMonth.total,
    thisMonth.total
  );

  return (
    <Card className={classes.root}>
      <WithLoading loading={stats.monthlyOrderStatsLoading}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                TOTAL ORDERS
              </Typography>
              <Typography variant="h3">{thisMonth.total}</Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <ShoppingCartIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
          <div className={classes.difference}>
            {isPostive ? (
              <ArrowUpwardIcon className={classes.improvedColor} />
            ) : (
              <ArrowDownwardIcon className={classes.worsenColor} />
            )}
            <Typography
              className={clsx(
                {
                  [classes.improvedColor]: isPostive,
                  [classes.worsenColor]: !isPostive,
                },
                classes.differenceValue
              )}
              variant="body2"
            >
              {absDiffPercent}%
            </Typography>
            <Typography variant="caption">Since last month</Typography>
          </div>
        </CardContent>
      </WithLoading>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
};

export default Budget;

import { createAsyncThunk } from "@reduxjs/toolkit";
import BusinessConfig, {
  PaymentMode,
  Helpline,
  OrderSettings,
  ReferralSettings,
} from "typing/BusinessConfig";
import { ServerTimestamp, makeRefs } from "config/Firebase";
import flatten from "flat";
import _ from "lodash";
import * as uuid from "uuid";
import { settingsActions } from "./slice";
import { AppState } from "store";
import { getStateFromThunkApi } from "utils/utils";

export const updateBusinessInfo = createAsyncThunk(
  "updateBusinessInfo",
  async (
    businessInfo: Pick<BusinessConfig, "name" | "description">,
    thunkApi
  ) => {
    try {
      const refs = makeRefs(getStateFromThunkApi(thunkApi));
      await refs.businessConfig
        .child("config")
        .update({ updatedAt: ServerTimestamp, ...businessInfo });
    } catch (error) {
      console.error({ error });
    }
  }
);

export const updatePaymentMode = createAsyncThunk(
  "updatePaymentMode",
  async (
    paymentMode: Pick<PaymentMode, "id" | "enabled" | "disableReason">,
    thunkApi
  ) => {
    try {
      const update = flatten(
        {
          paymentModes: { [paymentMode.id]: paymentMode },
        },
        { delimiter: "/" }
      ) as any;
      _.set(update, "updatedAt", ServerTimestamp);
      const refs = makeRefs(getStateFromThunkApi(thunkApi));
      await refs.businessConfig.child("config").update(update);
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateHelpline = createAsyncThunk(
  "updateHelpline",
  async (helpline: Helpline, thunkApi) => {
    try {
      const id = helpline.id as string;
      delete helpline.id;
      const update = flatten(
        {
          helplines: { [id]: helpline },
        },
        { delimiter: "/" }
      ) as any;
      _.set(update, "updatedAt", ServerTimestamp);
      const refs = makeRefs(getStateFromThunkApi(thunkApi));
      await refs.businessConfig.child("config").update(update);
    } catch (error) {
      console.log(error);
    }
  }
);

export const addHelpline = createAsyncThunk(
  "addHelpline",
  async (helpline: Omit<Helpline, "id">, thunkApi) => {
    try {
      const update = flatten(
        {
          helplines: { [uuid.v4()]: helpline },
        },
        { delimiter: "/" }
      ) as any;
      _.set(update, "updatedAt", ServerTimestamp);
      const refs = makeRefs(getStateFromThunkApi(thunkApi));
      await refs.businessConfig.child("config").update(update);
    } catch (error) {
      console.log(error);
    }
  }
);

export const removeHelpline = createAsyncThunk(
  "removeHelpline",
  async (helpline: Helpline, thunkApi) => {
    try {
      const refs = makeRefs(getStateFromThunkApi(thunkApi));

      await refs.businessConfig
        .child("config")
        .child("helplines")
        .child(helpline.id as string)
        .remove();

      await refs.businessConfig
        .child("config")
        .update({ updatedAt: ServerTimestamp });
    } catch (error) {
      console.log(error);
    }
  }
);

export const loadOrderSettings = createAsyncThunk(
  "loadOrderSettings",
  async (__, thunkApi) => {
    try {
      const state = getStateFromThunkApi(thunkApi) as AppState;
      if (state.settings.orderSettingsLoading) {
        const refs = makeRefs(state);
        const snap = await refs.orderSettings.once("value");
        thunkApi.dispatch(settingsActions.setOrderSettings(snap.val()));
      }
    } catch (error) {
      console.error({ error });
    }
  }
);

export const updateOrderSettings = createAsyncThunk(
  "updateOrderSettings",
  async (orderSettings: OrderSettings, thunkApi) => {
    try {
      const refs = makeRefs(getStateFromThunkApi(thunkApi));
      await refs.orderSettings.update(orderSettings);
      thunkApi.dispatch(settingsActions.setOrderSettings(orderSettings));
    } catch (error) {
      console.error({ error });
    }
  }
);

export const loadReferralSettings = createAsyncThunk(
  "loadReferralSettings",
  async (__, thunkApi) => {
    try {
      const state = getStateFromThunkApi(thunkApi) as AppState;
      if (state.settings.referralSettingsLoading) {
        const refs = makeRefs(state);
        const snap = await refs.referralSettings.once("value");
        thunkApi.dispatch(
          settingsActions.setReferralSettings(snap.val() ?? {})
        );
      }
    } catch (error) {
      console.error({ error });
    }
  }
);

export const updateReferralSettings = createAsyncThunk(
  "updateReferralSettings",
  async (referralSettings: ReferralSettings, thunkApi) => {
    try {
      const refs = makeRefs(getStateFromThunkApi(thunkApi));
      await refs.referralSettings.update(referralSettings);
      thunkApi.dispatch(settingsActions.setReferralSettings(referralSettings));
    } catch (error) {
      console.error({ error });
    }
  }
);

export const updateLiveSetting = createAsyncThunk(
  "updateLiveSetting",
  async (live: boolean, thunkApi) => {
    try {
      const refs = makeRefs(getStateFromThunkApi(thunkApi));

      await refs.businessConfig
        .child("config")
        .update({ updatedAt: ServerTimestamp, live });
    } catch (error) {
      console.error({ error });
    }
  }
);

export const updateOtherSettings = createAsyncThunk(
  "updateOtherSettings",
  async (
    settings: Pick<BusinessConfig, "type" | "mainStoreId" | "theme">,
    thunkApi
  ) => {
    try {
      const refs = makeRefs(getStateFromThunkApi(thunkApi));

      await refs.businessConfig
        .child("config")
        .update({ updatedAt: ServerTimestamp, ...settings });
    } catch (error) {
      console.error({ error });
    }
  }
);

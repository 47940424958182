import React, { useState, useCallback } from "react";
import SlideForm from "../SlideForm/SlideForm";
import { useThunkDispatch } from "store";
import { uploadFile, deleteFile } from "store/actions";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useSlideItem } from "store/slide/selectors";
import { updateSlide } from "store/slide/actions";

const SlideEdit: React.FC = () => {
  const thunkDispatch = useThunkDispatch();
  const [image, setImage] = useState<Blob | null>(null);
  const history = useHistory();
  const params = useParams<{ slideId: string }>();

  const slide = useSlideItem(params.slideId);

  const handleImageSelect = useCallback((file) => {
    if (file.size > 15 * 1024) {
      return "Image size should be less then 15kb";
    }

    setImage(file);
    return null;
  }, []);

  const handleFinish = useCallback(
    async (values) => {
      try {
        let imageUrl;
        if (image) {
          const imageUpload = await thunkDispatch(
            uploadFile({ path: "images/slides", file: image })
          );
          setImage(null);
          imageUrl = imageUpload.payload;
          if (slide?.imageUrl) {
            thunkDispatch(deleteFile(slide.imageUrl));
          }
        }
        await thunkDispatch(
          updateSlide({
            id: slide?.id,
            ...values,
            ...(imageUrl && { imageUrl }),
          })
        );
        history.goBack();
      } catch (error) {
        console.log(error);
        // TODO: handle
      }
    },
    [image, thunkDispatch, history, slide]
  );

  if (!slide) {
    return <Redirect to="/slides" />;
  }

  return (
    <SlideForm
      title="Edit Slide"
      subtitle={`Editing ${slide?.title}`}
      onImageSelect={handleImageSelect}
      onFinish={handleFinish}
      defaultValues={slide}
      okText="Save"
    />
  );
};

export default SlideEdit;

import { createSlice } from "@reduxjs/toolkit";
import BusinessConfig, {
  OrderSettings,
  ReferralSettings,
} from "typing/BusinessConfig";

interface State {
  config: BusinessConfig | null;
  configLoading: boolean;
  configUpdatedAt: number;
  orderSettings?: OrderSettings;
  orderSettingsLoading: boolean;
  referralSettingsLoading: boolean;
  referralSettings?: ReferralSettings;
}

const initialState: State = {
  config: null,
  configLoading: true,
  orderSettingsLoading: true,
  referralSettingsLoading: true,
  configUpdatedAt: 0,
};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
      state.configLoading = false;
      state.configUpdatedAt = action.payload.updatedAt;
    },
    setOrderSettings: (state, action) => {
      state.orderSettings = action.payload;
      state.orderSettingsLoading = false;
    },
    setReferralSettings: (state, action) => {
      state.referralSettings = action.payload;
      state.referralSettingsLoading = false;
    },
  },
});

const { actions, reducer } = settingSlice;

export { actions as settingsActions, reducer as settingsReducer };

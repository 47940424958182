import { useSelector } from "react-redux";
import { AppState } from "store";
import { useMemo } from "react";
import { filterArrayBySearch, filterDeletedItems } from "utils/utils";

export const useStoreList = () => useSelector((state: AppState) => state.store);

export const useStoreItem = (id: string) => {
  const data = useStoreList();
  return useMemo(() => data.list.find((e) => e.id === id), [data, id]);
};

export const useFilteredStore = () => {
  const data = useStoreList();

  return useMemo(
    () =>
      filterArrayBySearch(
        filterDeletedItems(data.list),
        data.searchText,
        "name"
      ),
    [data.list, data.searchText]
  );
};

import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import Item from "typing/Item";

interface State {
  data: {
    [storeId: string]: {
      list: Item[];
      loading: boolean;
    };
  };
  searchText: string;
}

const itemListSlice = createSlice({
  name: "itemList",
  initialState: {
    data: {},
    searchText: "",
  } as State,
  reducers: {
    patchList: (state, action) => {
      const storeId = action.payload.storeId;
      const list = action.payload.list;
      if (state.data[storeId]) {
        const oldList = state.data[storeId].list;
        state.data[storeId].list = _.unionBy(list, oldList, "id");
      } else {
        state.data[storeId] = {
          loading: false,
          list,
        };
      }
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
});

const { actions, reducer } = itemListSlice;

export { actions as itemListActions, reducer as itemListReducer };

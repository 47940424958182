import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import CategoryList from "./components/CategoryList";
import ItemList from "./components/ItemList";
import { useAppState } from "store/app/selectors";
import { useDispatch } from "react-redux";
import { appActions } from "store/app/slice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const StoreCatalog: React.FC = () => {
  const { catalogTab } = useAppState();
  const dispatch = useDispatch();

  const handleChange = (_: React.ChangeEvent<{}>, newTab: number) => {
    dispatch(appActions.setCatalogTab(newTab));
  };

  return (
    <div>
      <Tabs value={catalogTab} onChange={handleChange} centered>
        <Tab label="Categories" />
        <Tab label="Items" />
      </Tabs>
      <TabPanel value={catalogTab} index={0}>
        <CategoryList />
      </TabPanel>
      <TabPanel value={catalogTab} index={1}>
        <ItemList />
      </TabPanel>
    </div>
  );
};

export default StoreCatalog;

import { createSlice } from "@reduxjs/toolkit";
import Order from "typing/Order";
import { unsubscribeToOrderList } from "./actions";
import _ from "lodash";

interface State {
  searchText: string;
  list: Order[];
  loading: boolean;
  completedOrderList: Order[];
  completedOrdersListLoading: boolean;
}

const orderSlice = createSlice({
  name: "order",
  initialState: {
    searchText: "",
    list: [],
    loading: true,
    completedOrderList: [],
    completedOrderIdList: [],
    completedOrdersListLoading: true,
  } as State,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    addToCompletedOrderList: (state, action) => {
      state.completedOrderList = _.unionBy(
        state.completedOrderList,
        action.payload,
        "orderId"
      );
      state.completedOrdersListLoading = false;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(unsubscribeToOrderList.type, (state) => {
      state.list = [];
      state.loading = true;
      state.searchText = "";
      state.completedOrderList = [];
      state.completedOrdersListLoading = true;
    });
  },
});

const { actions, reducer } = orderSlice;

export { actions as orderActions, reducer as orderReducer };

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Store, { StoreStatus } from "typing/Store";
import { ServerTimestamp, makeRefs } from "config/Firebase";
import geohash from "latlon-geohash";
import { getUserZoneId } from "./utils";
import { deleteFile } from "store/actions";
import { getPathFromRef } from "utils/firebase";
import { getStateFromThunkApi } from "utils/utils";
import _ from "lodash";

export const subscribeToStoreList = createAction("subscribeToStoreList");
export const unsubscribeToStoreList = createAction("unsubscribeToStoreList");

export const setStoreListStatus = createAsyncThunk(
  "setStoreListStatus",
  (storeList: Pick<Store, "id" | "status">[], thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));

    const updates: any = {};

    storeList.forEach(({ id, status }) => {
      updates[`${id}/updatedAt`] = ServerTimestamp;
      updates[`${id}/status`] = status;
    });

    refs.store.update(updates);
  }
);

export const updateStoresStatus = createAsyncThunk(
  "updateStoresStatus",
  async (
    { storeIds, status }: { storeIds: string[]; status: StoreStatus },
    thunkApi
  ) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    const storePath = getPathFromRef(refs.store);
    const statusUpdates: { [path: string]: StoreStatus } = {};
    storeIds.forEach((storeId) => {
      statusUpdates[`/${storePath}/${storeId}/status`] = status;
    });
  }
);

export const addStore = createAsyncThunk(
  "addStore",
  async (store: Store, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    await refs.store.push({
      ...store,
      status: 0,
      g: geohash.encode(store.l[0], store.l[1]),
      zoneId: getUserZoneId(getStateFromThunkApi(thunkApi)),
      updatedAt: ServerTimestamp,
    });
  }
);

export const updateStore = createAsyncThunk(
  "updateStore",
  async (store: Store, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi));
    await refs.store.child(store.id).update({
      ..._.omit(store, ["id"]),
      g: geohash.encode(store.l[0], store.l[1]),
      updatedAt: ServerTimestamp,
    });
  }
);

export const deleteStore = createAsyncThunk(
  "deleteStore",
  async (store: Store, thunkApi) => {
    try {
      const refs = makeRefs(getStateFromThunkApi(thunkApi));
      if (store.imageUrl) {
        thunkApi.dispatch(deleteFile(store.imageUrl));
      }

      await refs.store.child(store.id).update({ deletedAt: ServerTimestamp });

      await refs.store.child(store.id).remove();
      await refs.catalog.child(store.id).remove();
    } catch (error) {
      console.log("error", error);
    }
  }
);

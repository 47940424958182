import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Theme,
} from "@material-ui/core";
import { useAuth } from "store/auth/selectors";
import { useDispatch } from "react-redux";
import { useConfig } from "store/settings/selectors";
import { subscribeToConfig } from "store/settings/actions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    marginLeft: "auto",
    height: 100,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
}));

const AccountProfile = () => {
  const config = useConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToConfig());
  }, [dispatch]);

  const classes = useStyles();

  const user = useAuth().user;

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h2">
              {user?.name}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              {user?.email}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              {config?.name ?? "Business"}
            </Typography>
          </div>
          <Avatar className={classes.avatar} alt={user?.name} />
        </div>
      </CardContent>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
};

export default AccountProfile;

import React from "react";
import { Doughnut } from "react-chartjs-2";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { useTodayOrderStat, useStats } from "store/stats/selectors";
import { WithLoading } from "components";

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100%",
  },
  chartContainer: {
    position: "relative",
    height: "300px",
  },
  stats: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  device: {
    textAlign: "center",
    padding: theme.spacing(1),
  },
  deviceIcon: {
    color: theme.palette.icon,
  },
}));

const OrdersRatio = () => {
  const classes = useStyles();
  const theme = useTheme<any>();

  const stats = useStats();

  const todayOrderStat = useTodayOrderStat();

  const data = {
    datasets: [
      {
        data: [
          todayOrderStat.active,
          todayOrderStat.delivered,
          todayOrderStat.cancelled,
        ],
        backgroundColor: [
          theme.palette.warning.main,
          theme.palette.success.main,
          theme.palette.error.main,
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      },
    ],
    labels: ["Pending", "Delivered", "Cancelled"],
  };

  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: "index",
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
    },
  };

  const types = [
    {
      title: "Pending",
      value: `${todayOrderStat.active}`,
      icon: <HourglassEmptyIcon />,
      color: theme.palette.warning.main,
    },
    {
      title: "Delivered",
      value: `${todayOrderStat.delivered}`,
      icon: <CheckCircleIcon />,
      color: theme.palette.success.main,
    },
    {
      title: "Cancelled",
      value: `${todayOrderStat.cancelled}`,
      icon: <CancelIcon />,
      color: theme.palette.error.main,
    },
  ];

  return (
    <Card className={classes.root}>
      <WithLoading loading={stats.dailyOrderStatsLoading}>
        <CardHeader title="Orders by status" />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Doughnut data={data} options={options} />
          </div>
          <div className={classes.stats}>
            {types.map((device) => (
              <div className={classes.device} key={device.title}>
                <span className={classes.deviceIcon}>{device.icon}</span>
                <Typography variant="body1">{device.title}</Typography>
                <Typography style={{ color: device.color }} variant="h2">
                  {device.value}
                </Typography>
              </div>
            ))}
          </div>
        </CardContent>
      </WithLoading>
    </Card>
  );
};

export default OrdersRatio;

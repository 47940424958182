import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { authReducer } from "./auth/slice";
import { useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { storeReducer } from "./store/slice";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { storeListSubscriber } from "./store/saga";
import { categoryListReducer } from "./category/slice";
import { categoryListSubscriber } from "./category/saga";
import { itemListReducer } from "./item/slice";
import { itemListSubscriber } from "./item/saga";
import { appReducer } from "./app/slice";
import { slideReducer } from "./slide/slice";
import { slideListSubscriber } from "./slide/saga";
import { userReducer } from "./user/slice";
import { exeListSubscriber, adminListSubscriber } from "./user/saga";
import { zoneReducer } from "./zone/slice";
import { zoneListSubscriber } from "./zone/saga";
import { orderReducer } from "./order/slice";
import { orderListSubscriber } from "./order/saga";
import { statsSubscriber } from "./stats/saga";
import { statsReducer } from "./stats/slice";
import { settingsReducer } from "./settings/slice";
import { settingSubscriber } from "./settings/saga";
import { couponListSubscriber } from "./coupon/saga";
import { couponReducer } from "./coupon/slice";

export const browserHistory = createBrowserHistory();

const rootReducer = combineReducers({
  auth: authReducer,
  zone: zoneReducer,
  store: storeReducer,
  categoryList: categoryListReducer,
  itemList: itemListReducer,
  slide: slideReducer,
  app: appReducer,
  user: userReducer,
  order: orderReducer,
  stats: statsReducer,
  settings: settingsReducer,
  coupon: couponReducer,
  router: connectRouter(browserHistory),
});

export type AppState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware(),
  routerMiddleware(browserHistory),
  sagaMiddleware,
] as any;

const store = configureStore<AppState>({
  reducer: rootReducer,
  middleware,
});

function* rootSaga() {
  yield all([
    storeListSubscriber(),
    categoryListSubscriber(),
    itemListSubscriber(),
    slideListSubscriber(),
    exeListSubscriber(),
    adminListSubscriber(),
    zoneListSubscriber(),
    orderListSubscriber(),
    statsSubscriber(),
    settingSubscriber(),
    couponListSubscriber(),
  ]);
}

sagaMiddleware.run(rootSaga);

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();

export default store;

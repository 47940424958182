import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import MoneyIcon from "@material-ui/icons/Money";
import { useStats, useThisMonthEarningStat } from "store/stats/selectors";
import { WithLoading } from "components";

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
}));

const TotalEarnings = () => {
  const classes = useStyles();

  const stats = useStats();

  const thisMonth = useThisMonthEarningStat();

  return (
    <Card className={classes.root}>
      <WithLoading loading={stats.monthlyEarningStatsLoading} color="secondary">
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                color="inherit"
                gutterBottom
                variant="body2"
              >
                TOTAL EARNINGS
              </Typography>
              <Typography color="inherit" variant="h3">
                ₹{thisMonth.total}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <MoneyIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </WithLoading>
    </Card>
  );
};

TotalEarnings.propTypes = {
  className: PropTypes.string,
};

export default TotalEarnings;

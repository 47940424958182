import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Theme,
  Container,
} from "@material-ui/core";
import { BackButton } from "components";
import _ from "lodash";
import Category from "typing/Category";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

interface CategoryFormProps {
  title: string;
  subtitle: string;
  defaultValues?: Category;
  onFinish: (values: Category) => Promise<void>;
}

const CategoryForm: React.FC<CategoryFormProps> = ({
  title,
  subtitle,
  defaultValues,
  onFinish,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const { register, errors, trigger, getValues } = useForm({
    defaultValues,
  });

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);
      if (await trigger()) {
        await onFinish(getValues());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [trigger, getValues, onFinish]);

  const hasError = (fieldName: string) => !!_.get(errors, fieldName);

  return (
    <Container maxWidth="md" className={classes.root}>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader title={title} subheader={subtitle} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the category name"
                  label="Category name"
                  margin="dense"
                  name="name"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={hasError("name")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleFinish}
              disabled={loading}
            >
              Save
            </Button>
            <BackButton>Cancel</BackButton>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
};

export default CategoryForm;

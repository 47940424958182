import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  TextField,
  Divider,
  Theme,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateBusinessInfo } from "store/settings/thunks";
import { subscribeToConfig } from "store/settings/actions";
import { useConfig, useConfigLoading } from "store/settings/selectors";
import { WithLoading } from "components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 300,
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));

const BusinessInfo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useConfigLoading();

  useEffect(() => {
    dispatch(subscribeToConfig());
  }, [dispatch]);

  return (
    <Card className={classes.root}>
      <WithLoading loading={loading}>
        {!loading && <BusinessInfoForm />}
      </WithLoading>
    </Card>
  );
};

const BusinessInfoForm = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const config = useConfig();
  const { register, trigger, getValues } = useForm({
    defaultValues: {
      name: config?.name ?? "",
      description: config?.description ?? "",
    },
  });

  const handleSave = useCallback(async () => {
    if (await trigger()) {
      const values = getValues() as any;
      dispatch(updateBusinessInfo(values));
    }
  }, [trigger, dispatch, getValues]);

  return (
    <form>
      <CardHeader
        subheader="Edit business informations"
        title="Business Information"
      />
      <Divider />
      <CardContent>
        <TextField
          className={classes.item}
          name="name"
          inputRef={register({ required: true })}
          fullWidth
          variant="outlined"
          label="Business Name"
          placeholder="Type business name"
        />
        <TextField
          className={classes.item}
          name="description"
          inputRef={register}
          fullWidth
          variant="outlined"
          label="Description"
          multiline
          rows={4}
          placeholder="Type description"
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" variant="outlined" onClick={handleSave}>
          Save
        </Button>
      </CardActions>
    </form>
  );
};

export default BusinessInfo;

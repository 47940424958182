import React, { useCallback } from "react";
import ZoneForm from "../ZoneForm/ZoneForm";
import { useThunkDispatch } from "store";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useZoneItem } from "store/zone/selectors";
import { updateZone } from "store/zone/actions";

const ZoneEdit: React.FC = () => {
  const thunkDispatch = useThunkDispatch();

  const history = useHistory();
  const { zoneId } = useParams<{
    zoneId: string;
  }>();

  const zone = useZoneItem(zoneId);

  const handleFinish = useCallback(
    async (values) => {
      try {
        await thunkDispatch(
          updateZone({
            id: zoneId,
            ...values,
          })
        );
        history.goBack();
      } catch (error) {
        console.log(error);
        // TODO: handle
      }
    },
    [thunkDispatch, history, zoneId]
  );

  if (!zone) {
    return <Redirect to={`/zones`} />;
  }

  return (
    <ZoneForm
      title="Edit Zone"
      subtitle={`Editing ${zone?.name} zone`}
      defaultValues={zone}
      onFinish={handleFinish}
    />
  );
};

export default ZoneEdit;

import { useSelector } from "react-redux";
import { AppState } from "store";
import { useMemo } from "react";
import { filterArrayBySearch } from "utils/utils";

export const useOrderList = () => useSelector((state: AppState) => state.order);

export const useOrderItem = (id: string) => {
  const data = useOrderList();
  return useMemo(() => data.list.find((it) => it.orderId === id), [data, id]);
};

export const useFilteredOrder = () => {
  const data = useOrderList();
  return useMemo(
    () => filterArrayBySearch(data.list, data.searchText, "name"),
    [data.list, data.searchText]
  );
};

export const useCompletedOrderData = () => {
  const data = useOrderList();
  return {
    loading: data.completedOrdersListLoading,
    list: data.completedOrderList,
  };
};

import { createSlice } from "@reduxjs/toolkit";
import { unsubscribeFromStats } from "./actions";
import moment from "moment";
import { MONTHLY_FORMAT } from "appContants";
import _ from "lodash";

export interface Stat {
  id: string;
  total: number;
  active: number;
  delivered: number;
  cancelled: number;
}

interface State {
  monthlyOrderStats: Stat[];
  dailyOrderStats: Stat[];
  monthlyEarningStats: Stat[];
  monthlyOrderStatsLoading: boolean;
  dailyOrderStatsLoading: boolean;
  monthlyEarningStatsLoading: boolean;
  totalUserCountStats: {
    [yyyymmdd: string]: number;
  };
  totalUserCountLoading: boolean;
}

const initialState: State = {
  monthlyOrderStats: [],
  dailyOrderStats: [],
  monthlyEarningStats: [],
  monthlyOrderStatsLoading: true,
  dailyOrderStatsLoading: true,
  monthlyEarningStatsLoading: true,
  totalUserCountStats: {
    [moment().format(MONTHLY_FORMAT)]: 0,
    [moment().subtract(1, "months").format(MONTHLY_FORMAT)]: 0,
  },
  totalUserCountLoading: true,
};

const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setMonthlyOrderStats: (state, action) => {
      state.monthlyOrderStats = action.payload;
      state.monthlyOrderStatsLoading = false;
    },
    setDailyOrderStats: (state, action) => {
      state.dailyOrderStats = action.payload;
      state.dailyOrderStatsLoading = false;
    },
    setMonthlyEarningStats: (state, action) => {
      state.monthlyEarningStats = action.payload;
      state.monthlyEarningStatsLoading = false;
    },
    setMonthlyUserStats: (state, action) => {
      state.totalUserCountStats = _.merge(
        state.totalUserCountStats,
        action.payload
      );
      state.totalUserCountLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(unsubscribeFromStats.type, (state) => {
      state = initialState;
    });
  },
});

const { actions, reducer } = statsSlice;

export { actions as statsActions, reducer as statsReducer };

import React, { useEffect } from "react";
import {
  Theme,
  makeStyles,
  Container,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";
import { WithLoading, BackButton } from "components";
import OrderListTable from "../OrderList/components/OrderListTable/OrderListTable";
import { useCompletedOrderData } from "store/order/selectors";
import { useDispatch } from "react-redux";
import { subscribeToOrderList } from "store/order/actions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  content: {
    padding: 0,
  },
}));

const CompletedOrderList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const completedOrderData = useCompletedOrderData();

  useEffect(() => {
    dispatch(subscribeToOrderList());
  }, [dispatch]);

  return (
    <WithLoading loading={completedOrderData.loading}>
      <Container className={classes.root}>
        <Card>
          <CardHeader
            title={`Completed Orders (${completedOrderData.list.length})`}
            action={
              <BackButton color="primary" size="small" variant="outlined">
                Back
              </BackButton>
            }
          />
          <Divider />
          <CardContent className={classes.content}>
            <OrderListTable orders={completedOrderData.list} />
          </CardContent>
        </Card>
      </Container>
    </WithLoading>
  );
};

export default CompletedOrderList;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, AppBar, Tabs, Tab } from "@material-ui/core";
import {
  BusinessInfo,
  OrderSettings,
  PaymentModes,
  Helplines,
  ReferralSettings,
} from "./components";
import OtherSettings from "./components/OtherSettings";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpanel-${index}`,
  };
}

const Settings = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={tab} onChange={(_, tab) => setTab(tab)}>
          <Tab label="About" {...a11yProps(0)} />
          <Tab label="Payment Modes" {...a11yProps(1)} />
          <Tab label="Order Settings" {...a11yProps(2)} />
          <Tab label="Referral Settings" {...a11yProps(3)} />
          <Tab label="Helplines" {...a11yProps(4)} />
          <Tab label="Others" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <BusinessInfo />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <PaymentModes />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <OrderSettings />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <ReferralSettings />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <Helplines />
      </TabPanel>
      <TabPanel value={tab} index={5}>
        <OtherSettings />
      </TabPanel>
    </div>
  );
};

export default Settings;

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";

import { SearchInput } from "components";
import { useDispatch, useSelector } from "react-redux";
import { itemListActions } from "store/item/slice";
import { AppState } from "store";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const ItemListToolbar: React.FC<any> = (props) => {
  const { storeId, className, ...rest } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const searchText = useSelector(
    (state: AppState) => state.itemList.searchText
  );

  const handleChange = useCallback(
    (event) => {
      dispatch(itemListActions.setSearchText(event.target.value));
    },
    [dispatch]
  );

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to={`/stores/${storeId}/items/add`}
        >
          Add item
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search item"
          onChange={handleChange}
          defaultValue={searchText}
        />
      </div>
    </div>
  );
};

ItemListToolbar.propTypes = {
  className: PropTypes.string,
};

export default ItemListToolbar;

import { AppState } from "store";
import { useSelector } from "react-redux";

export const useConfig = () => {
  return useSelector((state: AppState) => state.settings.config);
};

export const useConfigLoading = () => {
  return useSelector((state: AppState) => state.settings.configLoading);
};

export const useSettings = () => {
  return useSelector((state: AppState) => state.settings);
};

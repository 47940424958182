import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import Slide from "typing/Slide";
import { getMaxUpdatedAtFromArray } from "utils/utils";

interface SlideState {
  searchText: string;
  list: Slide[];
  loading: boolean;
  maxUpdatedAt: number;
}

const slideSlice = createSlice({
  name: "slide",
  initialState: {
    searchText: "",
    list: [],
    loading: true,
    maxUpdatedAt: 0,
  } as SlideState,
  reducers: {
    patchList: (state, action) => {
      state.list = _.unionBy(action.payload, state.list, "id");
      state.loading = false;
      state.maxUpdatedAt = getMaxUpdatedAtFromArray(
        state.maxUpdatedAt,
        action.payload
      );
    },
    setMaxUpdatedAt: (state, action) => {
      state.maxUpdatedAt = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
});

const { actions, reducer } = slideSlice;

export { actions as slideActions, reducer as slideReducer };

import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import { options } from "./chart";
import palette from "theme/palette";
import { useDailyOrderStats, useStats } from "store/stats/selectors";
import _ from "lodash";
import { DAILY_FORMAT } from "appContants";
import { WithLoading } from "components";
import moment from "moment";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 400,
  },
  chartContainer: {
    height: 400,
    position: "relative",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const OrdersOverview = () => {
  const classes = useStyles();

  const stats = useStats();

  const dailyStats = useDailyOrderStats();

  const sortedStats = _.orderBy(dailyStats, "id");

  const data = {
    labels: sortedStats.map(({ id }) =>
      moment(id, DAILY_FORMAT).format("D MMM")
    ),
    datasets: [
      {
        label: "Cancelled",
        backgroundColor: palette.error.light,
        data: sortedStats.map((stat) => stat.cancelled),
      },
      {
        label: "Delivered",
        backgroundColor: palette.success.light,
        data: sortedStats.map((stat) => stat.delivered),
      },
      {
        label: "Total",
        backgroundColor: palette.secondary.light,
        data: sortedStats.map((stat) => stat.total),
      },
    ],
  };

  return (
    <Card className={classes.root}>
      <WithLoading loading={stats.dailyOrderStatsLoading}>
        <CardHeader title="Orders Overview" />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar data={data} options={options} />
          </div>
        </CardContent>
        <Divider />
      </WithLoading>
    </Card>
  );
};

OrdersOverview.propTypes = {
  className: PropTypes.string,
};

export default OrdersOverview;

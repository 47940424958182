import React, { useCallback, useState } from "react";
import { Box, Avatar, Button, makeStyles, Theme } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  preview: {
    width: 140,
    height: 140,
  },
  imagePreview: {
    width: "100%",
    objectFit: "cover",
    height: 120,
  },
}));

interface Props {
  defaultImage?: string;
  onSelect: (file: Blob) => boolean;
  layout?: "circle" | "box";
  className?: string;
}

const FilePicker: React.FC<Props> = ({
  defaultImage,
  onSelect,
  layout = "circle",
  className,
}) => {
  const classes = useStyles();
  const [previewUrl, setPreviewUrl] = useState(
    defaultImage || "/images/placeholder-image.jpg"
  );

  const handleChange = useCallback(
    async (event) => {
      const files = event.target.files;
      if (files.length) {
        const file = files[0];
        if (onSelect(file)) {
          const blobUrl = URL.createObjectURL(file);
          setPreviewUrl(blobUrl);
        }
      }
    },
    [onSelect]
  );

  return (
    <Box
      className={className}
      display="flex"
      alignItems="center"
      flexDirection={layout === "circle" ? "row" : "column"}
    >
      {layout === "circle" && (
        <Avatar className={classes.preview} src={previewUrl} />
      )}

      {layout === "box" && (
        <img
          style={{ marginBottom: 8 }}
          className={classes.imagePreview}
          alt="preview"
          src={previewUrl}
        />
      )}

      <Box flex="1" mx={2}>
        <input
          accept=".png,.jpg"
          style={{ display: "none" }}
          id="contained-button-file"
          type="file"
          onChange={handleChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            fullWidth
            startIcon={<ImageIcon />}
            variant="contained"
            component="span"
          >
            Upload Image
          </Button>
        </label>
      </Box>
    </Box>
  );
};

export default FilePicker;

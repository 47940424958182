import React, { useCallback } from "react";
import ZoneForm from "../ZoneForm/ZoneForm";
import { useThunkDispatch } from "store";
import { useHistory } from "react-router-dom";
import { addZone } from "store/zone/actions";

const ZoneAdd: React.FC = () => {
  const thunkDispatch = useThunkDispatch();

  const history = useHistory();

  const handleFinish = useCallback(
    async (values) => {
      try {
        await thunkDispatch(addZone({ ...values }));
        history.goBack();
      } catch (error) {
        console.log(error);
        // TODO: handle
      }
    },
    [thunkDispatch, history]
  );

  return (
    <ZoneForm
      title="Add Zone"
      subtitle="Create new zone"
      onFinish={handleFinish}
    />
  );
};

export default ZoneAdd;

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ServerTimestamp, makeRefs } from "config/Firebase";
import _ from "lodash";
import { AppState } from "store";
import Zone from "typing/Zone";
import { getStateFromThunkApi } from "utils/utils";

export const subscribeToZoneList = createAction("subscribeToZoneList");
export const unsubscribeToZoneList = createAction("unsubscribeToZoneList");

export const addZone = createAsyncThunk(
  "addZone",
  async (zone: Omit<Zone, "id">, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi) as AppState);
    await refs.zone.push({
      ...zone,
      updatedAt: ServerTimestamp,
    });
  }
);

export const updateZone = createAsyncThunk(
  "updateZone",
  async (zone: Zone, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi) as AppState);
    await refs.zone.child(zone.id).update({
      ..._.omit(zone, ["id"]),
      updatedAt: ServerTimestamp,
    });
  }
);

export const deleteZone = createAsyncThunk(
  "deleteZone",
  async (zone: Zone, thunkApi) => {
    const refs = makeRefs(getStateFromThunkApi(thunkApi) as AppState);
    await refs.zone.child(zone.id).update({
      updatedAt: ServerTimestamp,
      deletedAt: ServerTimestamp,
    });
  }
);

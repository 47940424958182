export const MONTHLY_FORMAT = "YYYYMM";
export const DAILY_FORMAT = "YYYYMMDD";
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const themes = [
  {
    name: "Amber",
    data: {
      primaryColor: "#ffc107",
      accentColor: "#5563ff",
      textPrimary: "#000000",
    },
  },
  {
    name: "Indigo",
    data: {
      primaryColor: "#3f51b5",
      accentColor: "#dd33fa",
      textPrimary: "#ffffff",
    },
  },
  {
    name: "Cyan",
    data: {
      primaryColor: "#00bcd4",
      accentColor: "#f44336",
      textPrimary: "#ffffff",
    },
  },
  {
    name: "Orange",
    data: {
      primaryColor: "#ff9800",
      accentColor: "#2196f3",
      textPrimary: "#000000",
    },
  },
  {
    name: "Yellow",
    data: {
      primaryColor: "#ffeb3b",
      accentColor: "#9c27b0",
      textPrimary: "#000000",
    },
  },
  {
    name: "Lime",
    data: {
      primaryColor: "#cddc39",
      accentColor: "#ff9800",
      textPrimary: "#000000",
    },
  },
];

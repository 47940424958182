import { takeLeading, takeEvery, take, put, select } from "redux-saga/effects";
import { subscribeToOrderList, unsubscribeToOrderList } from "./actions";
import { eventChannel } from "redux-saga";
import { Firestore } from "config/Firebase";
import { orderActions } from "./slice";
import { getArrayFromQuerySnapshot } from "utils/firebase";
import { AppState } from "store";
import { Status } from "typing/Order";

export function* orderListSubscriber() {
  yield takeLeading(subscribeToOrderList.type, listen);
}

function* listen() {
  const user = yield select((state: AppState) => state.auth.user);
  const zoneId = user.zoneId;
  const businessId = user.businessId;
  const activeOrderListQuery = Firestore.collection("order")
    .where("businessId", "==", businessId)
    .where("zoneId", "==", zoneId)
    .where("status", ">=", Status.PLACED);

  const completedOrderListQuery = Firestore.collection("order")
    .where("businessId", "==", businessId)
    .where("zoneId", "==", zoneId)
    .where("state", "==", 0)
    .orderBy("time", "desc")
    .limit(100);

  const channel = eventChannel((emit) => {
    const unsubActiveOrderList = activeOrderListQuery.onSnapshot(
      (activeOrderListsnapshot) => {
        emit({ activeOrderListsnapshot });
      }
    );

    const unsubCompletedOrderList = completedOrderListQuery.onSnapshot(
      (completedOrderListSnapshot) => {
        emit({ completedOrderListSnapshot });
      }
    );

    return () => {
      unsubActiveOrderList();
      unsubCompletedOrderList();
    };
  });

  yield takeEvery(channel, function* ({
    activeOrderListsnapshot,
    completedOrderListSnapshot,
  }) {
    if (activeOrderListsnapshot) {
      yield put(
        orderActions.setList(getArrayFromQuerySnapshot(activeOrderListsnapshot))
      );
    }

    if (completedOrderListSnapshot) {
      yield put(
        orderActions.addToCompletedOrderList(
          getArrayFromQuerySnapshot(completedOrderListSnapshot)
        )
      );
    }
  });

  yield take(unsubscribeToOrderList.type);
  return channel.close();
}

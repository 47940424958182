import { Address } from "./Address";
import { Offer } from "./Offer";

export default interface Order {
  businessId: string;
  zoneId: string;
  store: {
    id: string;
    location: string;
    name: string;
    l: [number, number];
    phone?: string;
    imageUrl?: string;
    status?: number;
  };
  items: Item[];
  customer: {
    id: string;
    name: string;
  };
  dex: {
    id: string;
    name: string;
  };
  deliveryAddress: Address;
  itemsTotal: number;
  billAmount: number;
  cancellationRequested: boolean;
  charges: Charge[];
  instructions: string;
  orderId: string;
  paymentMode: string;
  status: Status;
  state: 0 | 1;
  time: number;
  couponCode: string;
  offer: Offer;
}

interface Charge {
  chargeAmount: number;
  chargeLabel: string;
}

interface Item {
  itemId: string;
  itemName: string;
  itemPrice: number;
  itemQuantity: number;
  itemVeg: boolean;
}

export enum Status {
  CANCELLED = -1,
  DELIVERED = -2,
  PLACED = 0,
  CONFIRMED = 1,
  PICKED_UP = 2,
}

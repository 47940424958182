import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { StoreListToolbar, StoreListTable } from "./components";
import { Theme } from "@material-ui/core";
import { useStoreList, useFilteredStore } from "store/store/selectors";
import { useDispatch } from "react-redux";
import { subscribeToStoreList } from "store/store/actions";
import { WithLoading } from "components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const StoreList = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const store = useStoreList();

  const storeList = useFilteredStore();

  useEffect(() => {
    dispatch(subscribeToStoreList());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <WithLoading loading={store.loading}>
        <StoreListToolbar />
        <div className={classes.content}>
          <StoreListTable storeList={storeList} />
        </div>
      </WithLoading>
    </div>
  );
};

export default StoreList;

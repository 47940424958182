import React, { useState, useCallback, useMemo } from "react";
import {
  TimePicker as MuiTimePicker,
  MuiPickersUtilsProvider,
  TimePickerProps,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

interface Props extends Partial<TimePickerProps> {
  name: string;
  label: string;
  defaultValue?: string;
  error?: boolean;
  format?: string;
}

const TimePicker = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      name,
      error,
      format = "HH:mm",
      defaultValue = "00:00",
      ...restProps
    },
    ref
  ) => {
    const [value, setValue] = useState<string>(defaultValue);

    const dateValue = useMemo(() => moment(value, format), [value, format]);

    const handleChange = useCallback(
      (time) => setValue(moment(time).format(format)),
      [format]
    );

    return (
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <MuiTimePicker
          {...restProps}
          fullWidth
          name={name}
          margin="normal"
          label={label}
          format={format}
          minutesStep={1}
          value={dateValue}
          onChange={handleChange}
          error={error}
          inputVariant="outlined"
          inputRef={ref}
        />
      </MuiPickersUtilsProvider>
    );
  }
);

export default TimePicker;

import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import Coupon from "typing/Coupon";

interface State {
  list: Coupon[];
  loading: boolean;
}

const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    searchText: "",
    list: [],
    loading: true,
  } as State,
  reducers: {
    patchList: (state, action) => {
      state.list = _.unionBy(action.payload, state.list, "id");
      state.loading = false;
    },
  },
});

const { actions, reducer } = couponSlice;

export { actions as couponActions, reducer as couponReducer };

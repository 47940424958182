import React, { useCallback, useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { Button } from "@material-ui/core";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";

const LocationPicker = React.forwardRef<HTMLInputElement, any>(
  ({ width, height, defaultValue, goToUserLocation, ...restProps }, ref) => {
    const [googleMaps, setGoogleMaps] = useState<any>(null);
    const [map, setMap] = useState<any>(null);
    const [marker, setMarker] = useState<any>(null);
    const [value, setValue] = useState<string>("");

    const [currentLocation, setCurrentLocation] = useState<{
      lat: number;
      lng: number;
    } | null>(null);

    const requestCurrentLocation = useCallback(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((location) => {
          setCurrentLocation({
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          });
        });
      }
    }, []);

    useEffect(() => {
      if (goToUserLocation) {
        requestCurrentLocation();
      }
    }, [requestCurrentLocation, goToUserLocation]);

    useEffect(() => {
      if (currentLocation && map) {
        const { lat, lng } = currentLocation;
        map.panTo({ lat, lng });
        map.setZoom(15);
      }
    }, [currentLocation, map]);

    const handlePanToCurrentLocation = useCallback(() => {
      if (currentLocation) {
        if (map) {
          const { lat, lng } = currentLocation;
          map.panTo({ lat, lng });
          map.setZoom(15);
        }
      } else {
        requestCurrentLocation();
      }
    }, [currentLocation, requestCurrentLocation, map]);

    const handleClick = useCallback(
      ({ lat, lng }) => {
        if (marker) {
          marker.setMap(null);
        }

        const position = { lat, lng };

        const newMarker = new googleMaps.Marker({
          position,
          map,
          title: "Store Location",
        });

        newMarker.setMap(map);
        setMarker(newMarker);
        setValue(`${lat},${lng}`);
      },
      [googleMaps, map, marker]
    );

    useEffect(() => {
      if (defaultValue && map && value.length === 0) {
        const [lat, lng] = defaultValue.split(",").map(Number);
        map.panTo({ lat, lng });
        map.setZoom(15);
        handleClick({ lat, lng });
      }
    }, [map, defaultValue, handleClick, value]);

    const handleLoad = useCallback((api) => {
      setGoogleMaps(api.maps);
      setMap(api.map);
    }, []);

    return (
      <div style={{ width, height }}>
        <div style={{ width, height: height - 40 }}>
          <input type="hidden" ref={ref} {...restProps} value={value} />
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={handleLoad}
            bootstrapURLKeys={{
              key: "AIzaSyCazSKj0oyt31k9bSWHL-jMxbW2jxOgi5E",
            }}
            defaultCenter={{
              lat: 22.2238215,
              lng: 68.0411852,
            }}
            defaultZoom={4.98}
            onClick={handleClick}
          />
          <Button
            fullWidth
            startIcon={<LocationSearchingIcon />}
            onClick={handlePanToCurrentLocation}
          >
            Locate me
          </Button>
        </div>
      </div>
    );
  }
);

export default LocationPicker;

import { takeLeading, put, takeEvery, take, select } from "redux-saga/effects";
import { subscribeToItemList, unsubscribeToItemList } from "./actions";
import { eventChannel } from "redux-saga";
import { itemListActions } from "./slice";
import { getListFromSnapshot } from "utils/firebase";
import { AppState } from "store";
import Item from "typing/Item";
import { getMaxUpdatedAtFromArray } from "utils/utils";
import { Refs, selectRefs } from "config/Firebase";

export function* itemListSubscriber() {
  yield takeLeading(subscribeToItemList.type, listen);
}

function* listen(action: any) {
  const refs: Refs = yield selectRefs();
  const storeId = action.payload;
  const itemList = yield select(
    (state: AppState) => state.itemList.data[storeId]
  );
  const maxUpdatedAt = getMaxUpdatedAtFromArray(0, itemList?.list ?? []);

  const channel = eventChannel((emit) => {
    const ref = refs.catalog
      .child(storeId)
      .child("item")
      .orderByChild("updatedAt")
      .startAt(maxUpdatedAt + 1);

    ref.on("value", (snapshot) => {
      emit({ snapshot });
    });

    return () => {
      ref.off();
    };
  });

  yield takeEvery(channel, function* ({ snapshot }) {
    const list = getListFromSnapshot<Item>(snapshot);
    yield put(itemListActions.patchList({ list, storeId }));
  });

  yield take(unsubscribeToItemList.type);
  return channel.close();
}

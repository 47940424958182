import { useSelector } from "react-redux";
import { AppState } from "store";
import { useMemo } from "react";
import { filterArrayBySearch, filterDeletedItems } from "utils/utils";

export const useItemList = (storeId: string) => {
  return (
    useSelector((state: AppState) => state.itemList.data[storeId]) ?? {
      loading: true,
      list: [],
    }
  );
};

export const useItem = (storeId: string, itemId: string) => {
  const data = useItemList(storeId);
  return useMemo(() => data.list.find((it) => it.id === itemId), [
    data,
    itemId,
  ]);
};

export const useFilteredItem = (storeId: string) => {
  const data = useItemList(storeId);
  const searchText = useSelector(
    (state: AppState) => state.itemList.searchText
  );
  return useMemo(
    () =>
      filterArrayBySearch(filterDeletedItems(data.list), searchText, "name"),
    [data.list, searchText]
  );
};

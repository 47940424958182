import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import { AppState } from "store";
import { useSelector } from "react-redux";
import { select, SelectEffect } from "redux-saga/effects";

export const Firebase = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
});

export const Auth = Firebase.auth();
export const Database = Firebase.database();
export const FirebaseStorage = Firebase.storage();
export const Firestore = Firebase.firestore();

export const ServerTimestamp = firebase.database.ServerValue.TIMESTAMP;

export const trashRef = Database.ref("trash");

export const makeRefs = (appState: AppState) => {
  const zoneId = appState.auth.user!.zoneId;
  const businessId = appState.auth.user!.businessId;

  const root = Database.ref(`data/${businessId}`);
  const stats = root.child("stats").child(zoneId);

  return {
    businessConfig: Database.ref(`business/${businessId}`),
    store: root.child("store"),
    catalog: root.child("catalog"),
    slide: root.child("slide"),
    user: root.child("user"),
    zone: root.child("zone"),
    monthlyUserCountStats: root.child("stats/user/count/monthly"),
    monthlyOrderCountStats: stats.child("order/count/monthly"),
    dailyOrderCountStats: stats.child("order/count/daily"),
    overallOrderCountStats: stats.child("order/count/overall"),
    monthlyEarningStats: stats.child("earning/monthly"),
    dailyEarningStats: stats.child("earning/daily"),
    overallEarningStats: stats.child("earning/overall"),
    orderSettings: root.child("orderSettings"),
    referralSettings: root.child("referralSettings"),
  };
};

export type Refs = ReturnType<typeof makeRefs>;

export const useRefs = () => {
  return useSelector((state: AppState) => makeRefs(state));
};

export function selectRefs(): SelectEffect {
  return select((state: AppState) => makeRefs(state));
}

export const getFirebaseServerTimestamp = async () => {
  try {
    const offsetSnap = await Database.ref("/.info/serverTimeOffset").once(
      "value"
    );

    return offsetSnap.val() + Date.now();
  } catch {
    console.error("Error while getting firebase server time offset");
    return NaN;
  }
};

import { takeLeading, put, takeEvery, take, select } from "redux-saga/effects";
import { subscribeToSlideList, unsubscribeToSlideList } from "./actions";
import { eventChannel } from "redux-saga";

import { slideActions } from "./slice";
import { getListFromSnapshot } from "utils/firebase";
import { AppState } from "store";
import Slide from "typing/Slide";
import { Refs, selectRefs } from "config/Firebase";

export function* slideListSubscriber() {
  yield takeLeading(subscribeToSlideList.type, listen);
}

function* listen() {
  const refs: Refs = yield selectRefs();
  const maxUpdatedAt = yield select(
    (state: AppState) => state.slide.maxUpdatedAt
  );

  const channel = eventChannel((emit) => {
    const ref = refs.slide.orderByChild("updatedAt").startAt(maxUpdatedAt + 1);

    ref.on("value", (snapshot) => {
      emit({ snapshot });
    });

    return () => {
      ref.off();
    };
  });

  yield takeEvery(channel, function* ({ snapshot }) {
    const list = getListFromSnapshot<Slide>(snapshot);
    yield put(slideActions.patchList(list));
  });

  yield take(unsubscribeToSlideList.type);
  return channel.close();
}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { ItemListTable, ItemListToolbar } from "./components";
import { Theme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useItemList, useFilteredItem } from "store/item/selectors";
import { useParams } from "react-router-dom";
import { subscribeToItemList, unsubscribeToItemList } from "store/item/actions";
import { WithLoading } from "components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const ItemList = () => {
  const { storeId } = useParams<{ storeId: string }>();

  const classes = useStyles();
  const itemList = useItemList(storeId);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToItemList(storeId));

    return () => {
      dispatch(unsubscribeToItemList());
    };
  }, [dispatch, storeId]);

  const itemListData = useFilteredItem(storeId);

  return (
    <div className={classes.root}>
      <WithLoading loading={itemList.loading}>
        <ItemListToolbar storeId={storeId} />
        <div className={classes.content}>
          <ItemListTable storeId={storeId} itemList={itemListData} />
        </div>
      </WithLoading>
    </div>
  );
};

export default ItemList;
